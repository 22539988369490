import "./Historia.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgHistoria from "./src/historia_1.png";
import ImgHistoria2 from "./src/historia-2.jfif";
import ImgHistoria3 from "./src/historia-3.jfif";
import moment from "moment";

import { useTranslation } from "react-i18next";

function Historia() {
    const [t, i18n] = useTranslation("global");

    const [backImage, setBackImage] = useState({ imagen: ImgHistoria });

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="Historia">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-historia-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-historia-s" alt={"historia"} src={backImage.imagen} />
          </div>
          <div className="div-historia-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-historia-s">
                <Col lg={11} md={12}>
                    <p className="title-historia-s">{t("nosotros.historia.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">1968</p>
                            <p className="line-text">
                                {t("nosotros.historia.1968")}
                                <br />
                                <br />
                                {t("nosotros.historia.1968-2")}
                            </p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-s line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                            <img className="img-line-s" alt="historia" src={ImgHistoria2} />
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">1976</p>
                            <p className="line-text">{t("nosotros.historia.1976")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">1990</p>
                            <p className="line-text">{t("nosotros.historia.1990")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">1995</p>
                            <p className="line-text">{t("nosotros.historia.1995")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">2000</p>
                            <p className="line-text">{t("nosotros.historia.2000")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">2001</p>
                            <p className="line-text">{t("nosotros.historia.2001")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">2006</p>
                            <p className="line-text">{t("nosotros.historia.2006")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">2007</p>
                            <p className="line-text">{t("nosotros.historia.2007")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">2008</p>
                            <p className="line-text">{t("nosotros.historia.2008")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">2011</p>
                            <p className="line-text">{t("nosotros.historia.2011")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">2015</p>
                            <p className="line-text">{t("nosotros.historia.2015")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">2016</p>
                            <p className="line-text">{t("nosotros.historia.2016")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-r">2020</p>
                            <p className="line-text">{t("nosotros.historia.2020")}</p>
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-l">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-line-historia">
                <Col lg={11} md={12}>
                    <Row>
                        <Col lg={{ span: 5, order: 1 }} md={0} sm={0} xs={0} className="d-none d-lg-block">
                            <img className="img-line-e" alt="historia" src={ImgHistoria3} />
                        </Col>
                        <Col lg={{ span: 2, order: 2 }} md={{ span: 3, order: 2}} sm={{ span: 2, order: 2}} xs={{ span: 2, order: 2}}>
                            <div className="line-historia line-historia-r line-historia-e">
                            </div>
                        </Col>
                        <Col lg={{ span: 5, order: 3 }} md={{ span: 9, order: 3}} sm={{ span: 10, order: 3}} xs={{ span: 10, order: 3}}>
                            <p className="line-title line-title-l">2023</p>
                            <p className="line-text">{t("nosotros.historia.2023")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default Historia;
