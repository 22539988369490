import "./Tarjeta.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Row } from "react-bootstrap";
import Email from "./src/Email.svg";
import Linkedin from "./src/Linkedin.svg";
import Whatsapp from "./src/Whatsapp.svg";
import Correo from "./src/correo.svg";
import Telefono from "./src/telefono.svg";
import Celular from "./src/celular.svg";
import Direccion from "./src/direccion.svg";
import AgregarContacto from "./src/agregar_contacto.svg";
import Logo from "./src/tabsa_logo.png";

function Tarjeta() {
    
    const { email } = useParams();

    const [tarjeta, setTarjeta] = useState({});
    
    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/tarjetas/tarjeta/${email}`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(dataResponse => {
                    console.log(dataResponse.data);
                    console.log(dataResponse.data.descripcion.includes("\n"))
                    setTarjeta(dataResponse.data);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, []);

    return (
        <div className="div-tarjeta">
            {
                tarjeta.imagen &&
                <div className="div-img-tarjeta">
                    <img src={tarjeta.imagen} alt="" className="img-tarjeta" />
                    <div className="div-img-titulo">
                        <div className="div-titulo">
                            <p className="p-nombre">{tarjeta.nombre}</p>
                            <p className="p-cargo">{tarjeta.cargo}</p>
                        </div>
                    </div>    
                </div>
            }
            <div className="div-info">
                <div className="div-icon-buttons">
                    {
                        tarjeta.whatsapp &&
                        <a href={`https://wa.me/${tarjeta.whatsapp.replace("+","").trim()}/?text=Hola!`} target="_blank">
                            <img src={Whatsapp} alt="whatsapp" className="icon-button" />
                        </a>
                    }
                    {
                        tarjeta.linkedin &&
                        <a href="#" onClick={() => { window.open(tarjeta.linkedin, "_blank") }}>
                            <img src={Linkedin} alt="linkedin" className="icon-button" />
                        </a>
                    }
                    {
                        tarjeta.email &&
                        <a href={`mailto:${tarjeta.email}`} target="_blank">
                            <img src={Email} alt="email" className="icon-button" />
                        </a>
                    }
                </div>
                <div className="div-contacto">
                    <p className="p-contacto-titulo">Contáctame</p>
                    <br />
                    <div className="div-row-contacto">
                        <a className="link-contacto" target="_blank" href={`mailto:${tarjeta.email}`}>
                            <img src={Correo} alt="email" className="icon-contacto" />
                            {tarjeta.email}
                        </a>
                    </div>
                    {
                        tarjeta.telefono_fijo &&
                        <div className="div-row-contacto">
                            <a className="p-contacto" target="_blank" href={`tel:${tarjeta.telefono_fijo}`}>
                                <img src={Telefono} alt="telefono_fijo" className="icon-contacto" />
                                {tarjeta.telefono_fijo}
                            </a>
                        </div>
                    }
                    {
                        tarjeta.telefono_movil &&
                        <div className="div-row-contacto">
                            <a className="p-contacto" target="_blank" href={`tel:${tarjeta.telefono_movil}`}>
                                <img src={Celular} alt="telefono_movil" className="icon-contacto" />
                                {tarjeta.telefono_movil}
                            </a>
                        </div>
                    }
                    {
                        tarjeta.direccion &&
                        <div className="div-row-contacto">
                            <a className="p-contacto" target="_blank" href={`https://www.google.com/maps/search/${tarjeta.direccion.replaceAll(" ", "+")}`}>
                                <img src={Direccion} alt="direccion" className="icon-contacto" />
                                {tarjeta.direccion}
                            </a>
                        </div>
                    }
                </div>
                <div className="div-guardar-contacto">
                    <Button className="btn-guardar-contacto" onClick={() => {
                        
                        var vcard = "BEGIN:VCARD\nVERSION:4.0\nFN:" + tarjeta.nombre + "\nTEL;TYPE=work,voice:" + tarjeta.telefono_movil + "\nEMAIL:" + tarjeta.email + "\nADR:" + tarjeta.direccion + "\nEND:VCARD";
                        var blob = new Blob([vcard], { type: "text/vcard" });
                          
                        if (navigator.share) {
  
                            navigator.share({
                            
                              title: 'Nuevo contacto',
                              text: 'Guardar contacto',
                              files: [new File([blob], tarjeta.nombre + ".vcf", { type: 'text/vcard' })],
                            }).then(() => { });
                        
                        } else {

                            var url = URL.createObjectURL(blob);

                            const newLink = document.createElement('a');
                            newLink.download = tarjeta.nombre + ".vcf";
                            newLink.textContent = tarjeta.nombre;
                            newLink.href = url;
                              
                            newLink.click();
                        }
                    }}>
                        <img src={AgregarContacto} alt="agregar contacto" className="icon-agregar-contacto" />
                        Guardar contacto
                    </Button>
                </div>
                <div className="div-descripcion">
                    {
                        tarjeta.titulo_descripcion &&
                        <p className="p-titulo-descripcion">{tarjeta.titulo_descripcion}</p>
                    }
                    {
                        tarjeta.descripcion &&
                        <p className="p-descripcion">{tarjeta.descripcion}</p>
                    }
                </div>
                <div className="div-footer">
                    <img src={Logo} className="img-logo" alt="tabsa" />
                </div>
            </div>
        </div>
    )
}

export default Tarjeta;