import "./NuestrosServicios.css";
import { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import ArrowLeft from "./src/arrow-left.svg";
import ArrowRight from "./src/arrow-right.svg";

import { useTranslation } from "react-i18next";

function NuestrosServicios({ clickOut }) {
    const [t, i18n] = useTranslation("global");

    const [servicios, setServicios] = useState([]);
    const [pageServicios, setPageServicios] = useState(0);

    useEffect(() => {

        fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            let newServices = data.data.map(s => ({ img: s.cardImg, tipo: "", nombre: s.cardTitulo[t("lang")], detalle: s.cardDetalle[t("lang")], url: `/rutas/${s.code}`, nave: "" }));

            fetch(`${process.env.REACT_APP_API_BASEURL}/Servicios/servicios`, { method: "GET" }).then(response => {
                if (response.ok) {
                    response.json().then(data => {
                        newServices = newServices.concat(data.data.map(s => ({ img: s.cardImg, tipo: "", nombre: s.cardTitulo[t("lang")], detalle: s.cardDetalle[t("lang")], url: `/servicios/${s.code}`, nave: "" })));
                        setServicios(newServices);
                    })
                }
                else {
                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                }
            }).catch(error => {
                console.log(JSON.stringify(error));
            });
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
    }, []);

    return (
        <Container className="div-servicios" onClick={() => { clickOut(false, false, false, false, false) }}>
            <Row className="justify-content-center">
                <Col lg={11} sm={10} xs={10}>
                    <Row>
                        <Col lg={12} style={{ textAlign: "left" }}>
                            <p className="title-servicios">{t("nuestros-servicios.servicios")}</p>
                            <p className="subtitle-servicios">{t("nuestros-servicios.sub-servicios")}</p>
                        </Col>
                    </Row>
                    <Row className="div-listado-servicios">
                        {
                            servicios && servicios.length > 0 &&
                            servicios.slice(pageServicios * 6, (pageServicios + 1) * 6).map((servicio, index) => {
                                return (
                                    <Col className="col-card-servicio d-none d-lg-block" key={`${index}-card-servicio`} lg={4}>
                                        <Card className="card-servicio" onClick={() => {
                                            window.location.href = servicio.url
                                        }}>
                                            <Row>
                                                <Col lg={12}>
                                                    <img className="img-servicio" alt={servicio.nombre} src={servicio.img} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="div-detalle-servicio" lg={12}>
                                                    <p className="p-nombre-servicio">{servicio.tipo} <span className="p-b">{servicio.nombre}</span></p>
                                                    <p className="p-detalle-servicio">{servicio.detalle}</p>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                        {
                            servicios && servicios.length > 0 &&
                            <Col className="col-card-servicio d-block d-lg-none" key={`${pageServicios}-card-servicio-xs`} lg={4} md={12} sm={12}>
                                <Card className="card-servicio" onClick={() => {
                                    window.location.href = servicios[pageServicios].url
                                }}>
                                    <Row>
                                        <Col lg={12}>
                                            <img className="img-servicio" alt={servicios[pageServicios].nombre} src={servicios[pageServicios].img} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="div-detalle-servicio" lg={12}>
                                            <p className="p-nombre-servicio">{servicios[pageServicios].tipo} <span className="p-b">{servicios[pageServicios].nombre}</span></p>
                                            <p className="p-detalle-servicio">{servicios[pageServicios].detalle}</p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        }
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <img className="img-button img-button-left d-none d-lg-block" alt="Servicios previos" src={ArrowLeft} onClick={() => {
                                if (servicios.length > 6) {
                                    setPageServicios(pageServicios - 1 < 0 ? Math.ceil(servicios.length / 6) - 1 : pageServicios - 1);
                                }
                            }} />
                            <img className="img-button-xs d-block d-lg-none" alt="Servicios previos" src={ArrowLeft} onClick={() => {
                                setPageServicios(pageServicios - 1 < 0 ? servicios.length - 1 : pageServicios - 1);
                            }} />
                        </Col>
                        <Col lg={1} md={1} sm={2} xs={2}>
                            <img className="img-button d-none d-lg-block" alt="Servicios siguientes" src={ArrowRight} onClick={() => {
                                if (servicios.length > 6) {
                                    setPageServicios(pageServicios + 1 < Math.ceil(servicios.length / 6) ? pageServicios + 1 : 0);
                                }
                            }} />
                            <img className="img-button d-block d-lg-none" alt="Servicios siguientes" src={ArrowRight} onClick={() => {
                                setPageServicios(pageServicios + 1 < servicios.length ? pageServicios + 1 : 0);
                            }} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}

export default NuestrosServicios;