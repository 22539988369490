import "./AdminCMS.css";
import AdminNavbar from "./components/navbar/AdminNavbar";

function AdminCMS() {

    let usuario = sessionStorage.getItem("admin-user");

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    return (
        <>
            <AdminNavbar />
        </>
    )
}

export default AdminCMS;