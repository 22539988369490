import "./VolverInicio.css";
import BackIcon from "./src/back-icon.svg";
import { Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

function VolverInicio({ Path, Texto }) {
    const [t, i18n] = useTranslation("global");

    return (
        <div className="back-home-button" onClick={() => {
            window.location.href = Path;
        }}>
            <Row className="justify-content-center">
                <Col>
                    <img className="back-home-icon" src={BackIcon} alt={"Volver"} />
                </Col>
            </Row>
            <Row className="row-margin">
                <Col>
                    <p className="back-home-text">{t(Texto)}</p>
                </Col>
            </Row>
        </div>
    )
}

export default VolverInicio;