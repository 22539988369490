import "./Navegacion.css";
import { Col, Container, Nav, Navbar, NavDropdown, Row, Form, Button } from "react-bootstrap";
import Logo from "./src/logo_tabsa.png";
import LogoColor from "./src/logo-tabsa-color.png";
import IconoClose from "./src/close-circle.svg";
import IconoIngles from "./src/english.jpg";
import IconoEspanol from "./src/bandera.png";
import IconoUsuario from "./src/user-icon.svg";
import DownArrow from "./src/down-arrow.svg";
import Check from "./src/check.svg";
import Google from "./src/google.svg";
import Email from "./src/email.svg";
import Password from "./src/password.svg";
import HidePasswordIcon from "./src/hide-pass.svg";
import ShowPasswordIcon from "./src/show-pass.svg";
import CryptoJS from "crypto-js";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

function Navegacion({ clickOut, showSelectLanguage, showLogin }) {
  const [t, i18n] = useTranslation("global");
  const [rutas, setRutas] = useState([]);
  const [servicios, setServicios] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [recordar, setRecordar] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          setRutas(data.data);
        })
      }
      else {
        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
      }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/Servicios/servicios`, { method: "GET" }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          setServicios(data.data);
        })
      }
      else {
        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
      }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  return (
    <>
      <Navbar expand="md" className="bg-body-tertiary sticky-bar d-none d-sm-block">
        <Container>
          <Navbar.Brand href="/">
            <img alt="logo" className="logo" src={Logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-center" id="basic-navbar-nav">
            <Nav className="sticky-bar-text">
              <NavDropdown className="sticky-bar-text sticky-bar-text-home" title={t("navegacion.inicio")} id="basic-nav-dropdown">
                  <NavDropdown.Item className="sticky-bar-text" href="/nosotros">{t("navegacion.quienes-somos")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/historia">{t("navegacion.historia")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/ejecutivos">{t("navegacion.ejecutivos")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/preguntas">{t("navegacion.preguntas-frecuentes")}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="sticky-bar-text" href="https://seguimiento.tabsa.cl/">{t("navegacion.sigue-carga")}</Nav.Link>
              <NavDropdown className="sticky-bar-text" title={t("navegacion.nuestras-rutas")} id="basic-nav-dropdown">
                {
                  rutas.map(r => (<NavDropdown.Item className="sticky-bar-text" href={`/rutas/${r.code}`}>{r.navbar[t("lang")]}</NavDropdown.Item>))
                }
              </NavDropdown>
              <Nav.Link className="sticky-bar-text" href="/estado-cruces">{t("navegacion.estado-cruces")}</Nav.Link>
              <NavDropdown className="sticky-bar-text" title={t("navegacion.otros-servicios")} id="basic-nav-dropdown">
                {
                  servicios.map(s => (<NavDropdown.Item className="sticky-bar-text" href={`/servicios/${s.code}`}>{s.navbar[t("lang")]}</NavDropdown.Item>))
                }
              </NavDropdown>

              <Nav.Link className="sticky-bar-text nav-link-acceso">
                <img className="user-icon" alt={"usuario"} src={IconoUsuario} onClick={() => { 
                  clickOut(false, false, false, false, !showLogin)
                  }}/>
                  <span  onClick={() => { clickOut(false, false, false, false, !showLogin)}}>
                    Mi Cuenta
                  </span>
                

                {showLogin && 
                <div className="user-login-div">
                  <Row className="justify-content-center">
                    <Col lg={12}>
                      <p className="user-login-title">{t("navegacion.login.acceso-clientes")}</p>
                      {/* <p className="user-login-acceso-alert">{t("navegacion.login.acceso-alert")}</p> */}
                    </Col>
                  </Row>
                  
                  <Row className="form-field-row">
                    <Col lg={12}>
                      <Button className="form-button-login" onClick={() => {
                        window.location.href = `https://plataforma-usuarios.tabsa.cl/`
                      }}>{t("Acceso Clientes Persona")}</Button>
                    </Col>
                  </Row>
                </div>
              }
              </Nav.Link>

              <Nav.Link className="sticky-bar-text" onClick={() => { 
                  clickOut(false, false, false, !showSelectLanguage, false)
                }}>
                <img alt="arrow-language" className="arrow-language" src={DownArrow} />
                <img alt={t("lang")} className="language-icon" src={t("lang") === "es" ? IconoEspanol : IconoIngles} />
              
                {
                  showSelectLanguage &&
                    <div className="select-language-div">
                      <Row className="justify-content-center select-language-row" onClick={() => {
                          localStorage.setItem('i18nextLng', "es");
                          i18n.changeLanguage("es");
                        }}>
                        <Col lg={6} md={4} className="select-language">
                          <img alt={t("lang")} className="language-icon" src={IconoEspanol} />
                        </Col>
                        <Col lg={2} md={4}>
                          {
                            t("lang") === "es" &&
                            <img className="img-check-lang" src={Check} alt="check" />
                          }
                        </Col>
                      </Row>
                      <Row className="justify-content-center select-language-row" onClick={() => {
                          localStorage.setItem('i18nextLng', "en");
                          i18n.changeLanguage("en");
                        }}>
                        <Col lg={6} md={4} className="select-language">
                          <img alt={t("lang")} className="language-icon" src={IconoIngles} />
                        </Col>
                        <Col lg={2} md={4}>
                          {
                            t("lang") === "en" &&
                            <img className="img-check-lang" src={Check} alt="check" />
                          }
                        </Col>
                      </Row>
                    </div>
                }
              </Nav.Link>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      <Navbar expand="xs" className="bg-body-tertiary sticky-bar d-block d-sm-none">
        <Container className="no-gutter justify-content-end">
          <div className="language">
            <Row>
              <Col lg={4}>
                <img className="user-icon" alt={"usuario"} src={IconoUsuario} onClick={() => { 
                  clickOut(false, false, false, false, !showLogin)
                 }}/>
                {
                  showLogin && 
                  <div className="user-login-div-xs">
                    <img alt="close" className="close-button-user-xs" src={IconoClose} onClick={() => clickOut(false, false, false, false, !showLogin)} />
                    <Row className="justify-content-center">
                      <Col lg={12}>
                        <p className="user-login-title">{t("navegacion.login.acceso-clientes")}</p>
                      </Col>
                    </Row>
                    <Row className="form-field-row">
                      <Col lg={12}>
                        <Button className="form-button-login" onClick={() => {
                          window.location.href = `https://plataforma-usuarios.tabsa.cl/`
                        }}>{t("Acceso Clientes Persona")}</Button>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col lg={12}>
                        {
                          /*
                          <Button className="form-button-login"><img className="img-btn-login-icon" src={Google} alt={"google"} /> {t("navegacion.login.acceder-google")}</Button>
                          */
                        }
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg={6} sm={6} xs={6}>
                        <img alt="logo" className="logo-login-xs" src={LogoColor} />
                      </Col>
                    </Row>
                    <Row className="justify-content-center">
                      <Col lg={12} sm={12} xs={12}>
                        <p className="copyright-login-xs">{t("footer.copyright")}</p>
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col lg={2}></Col>
            </Row>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse className="justify-content-center" id="basic-navbar-nav-xs">
            <img alt="close" className="close-button-xs" src={IconoClose} onClick={() => {
              document.getElementsByClassName("navbar-toggler")[1].click();
            }} />
            <br />
            <img alt="logo" className="logo-xs" src={LogoColor} />
            <Nav className="sticky-bar-text">
              <NavDropdown className="sticky-bar-text" title={t("navegacion.inicio")} id="basic-nav-dropdown">
                  <NavDropdown.Item className="sticky-bar-text" href="/nosotros">{t("navegacion.quienes-somos")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/historia">{t("navegacion.historia")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/ejecutivos">{t("navegacion.ejecutivos")}</NavDropdown.Item>
                  <NavDropdown.Item className="sticky-bar-text" href="/preguntas">{t("navegacion.preguntas-frecuentes")}</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link className="sticky-bar-text" href="https://seguimiento.tabsa.cl/">{t("navegacion.sigue-carga")}</Nav.Link>
              <NavDropdown className="sticky-bar-text" title={t("navegacion.nuestras-rutas")} id="basic-nav-dropdown">
                {
                  rutas.map(r => (<NavDropdown.Item className="sticky-bar-text" href={`/rutas/${r.code}`}>{r.navbar[t("lang")]}</NavDropdown.Item>))
                }
              </NavDropdown>
              <Nav.Link className="sticky-bar-text" href="/estado-cruces">{t("navegacion.estado-cruces")}</Nav.Link>
              <NavDropdown className="sticky-bar-text" title={t("navegacion.otros-servicios")} id="basic-nav-dropdown">
                {
                  servicios.map(s => (<NavDropdown.Item className="sticky-bar-text" href={`/servicios/${s.code}`}>{s.navbar[t("lang")]}</NavDropdown.Item>))
                }
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navegacion;