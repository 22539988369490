import "./EstadoCruce.css";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import IconoEstadoCancelado from "./src/EstadoCruceCancelado.svg";
import IconoEstadoDemora from "./src/EstadoCruceDemora.svg";
import IconoEstadoEnViaje from "./src/EstadoCruceEnViaje.svg";

import { useTranslation } from "react-i18next";

function EstadoCruce({ clickOut, cruces, crucesSuspendidos, showCruce }) {
    const [t, i18n] = useTranslation("global");

    const [showIndex, setShowIndex] = useState(0);

    

    useEffect(() => {
        let intervalId;
    
        function cambiarCruce() {
            let newCruce = (showIndex + 1 < crucesSuspendidos.length) ? showIndex + 1 : 0;
            setShowIndex(newCruce);
        }
    
        if (showCruce) {
            intervalId = setInterval(cambiarCruce, 4000);
        }
    
        return () => clearInterval(intervalId);
    }, [showCruce, crucesSuspendidos, showIndex]);
    
    return (
        <>
            {
                showCruce &&
                <div className="estadocruce-header" onClick={() => { clickOut(false, false, false, false, false) }}>
                    <Row className="justify-content-md-center">
                        <Col lg={8} md={11} sm={12} xs={12}>
                            <Row className="justify-content-md-center">
                                <Col lg={1} md={1} sm={1} xs={1}></Col>
                                <Col lg={2} md={2} sm={3} xs={3}>
                                    {t("estado-cruce.estado")}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {t("estado-cruce.origen")}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {t("estado-cruce.destino")}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {t("estado-cruce.hora")}
                                </Col>
                                <Col lg={2} md={2} sm={2} xs={2}>
                                    {t("estado-cruce.fecha")}
                                </Col>
                            </Row>
                            {
                                crucesSuspendidos && crucesSuspendidos.length > 0 && 
                                crucesSuspendidos.map((c, index) => {
                                        if (showIndex === index) {
                                            return (
                                                <Row key={`${index}-${c.origen}-${c.destino}`} className={`justify-content-md-center cruces`} >
                                                    <Col lg={1} md={1} sm={1} xs={1} className="icono-col">
                                                        <img className="icono-estado" alt="Estado cruce" src={(c.estado === "cancelado" || c.estado === "suspendido") ? IconoEstadoCancelado : (c.estado === "demora") ? IconoEstadoDemora : IconoEstadoEnViaje} />
                                                    </Col>
                                                    <Col lg={2} md={2} sm={3} xs={3}>
                                                        {c.estado.toUpperCase()}
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2}>
                                                        {c.origen.toUpperCase()}
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2}>
                                                        {c.destino.toUpperCase()}
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2}>
                                                        {c.hora.toUpperCase()}
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2}>
                                                        {c.fecha.toUpperCase()}
                                                    </Col>
                                                </Row>
                                            )
                                        }
    
                                    })
                            }
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default EstadoCruce;