import "./InformacionInteres.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Chat from "../../components/Chat/Chat";
import Footer from "../../components/Footer/Footer";
import LogoColor from "./src/logo-tabsa-color.png";
import moment from "moment";

import { useTranslation } from "react-i18next";

function InformacionInteres() {
    const [t, i18n] = useTranslation("global");
    const { code } = useParams();

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [ruta, setRuta] = useState({
        titulo: { es: "", en: "es" },
        contenido: []
    });

    useEffect(() => {
        
        fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
          if (response.ok) {
            response.json().then(data => {
              setRuta(data.data);
            })
          }
          else {
            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
          }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
  
    }, [code])
    
    useEffect(() => {
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="CMSIInformacionInteres">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-cmsi-informacion-interes-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              ruta.imgBack &&
              <img className="img-cmsi-informacion-interes-s" alt={ruta.code} src={ruta.imgBack} />
            }
          </div>
          <div className="div-cmsi-ii-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none align-center">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              {
                ruta.infoInteres && ruta.infoInteres.length > 0 &&
                ruta.infoInteres.map((s, idx) => {
                    if (s.titulo) {
                        return (
                            <Row key={`ii-rt-${idx}`} className="justify-content-center">
                                <Col lg={11} sm={11} xs={11}>
                                    <Row className="row-cmsi-informacion-interes-s">
                                        <Col lg={12}>
                                            <p className="title-cmsi-informacion-interes-s">{s.titulo[t("lang")]}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                    else if (s.texto) {
                        return (
                            <Row key={`rtx-${idx}`} className="justify-content-center">
                                <Col lg={11} sm={11} xs={11}>
                                    <Row>
                                        <Col lg={12}>
                                            <p className="p-cmsi-informacion-interes-s">{s.texto[t("lang")]}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                    else if (s.subtitulo) {
                        return (
                            <Row key={`rst-${idx}`} className="justify-content-center">
                                <Col lg={11} sm={11} xs={11}>
                                    <Row>
                                        <Col lg={12}>
                                            <p className="subtitle-cmsi-informacion-interes-s">{s.subtitulo[t("lang")]}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                    else if (s.listaImagen && s.listaImagen.formato === 1) {
                        return (
                        <>
                            <Row key={`rlt-${idx}}`} className="justify-content-center">
                                <Col lg={11} sm={11} xs={11}>
                                    <Row>
                                        <Col lg={12}>
                                            <ul>
                                                {
                                                    s.listaImagen.listas && s.listaImagen.listas.length > 0 &&
                                                    s.listaImagen.listas.map((l) => {
                                                        return (
                                                            <li key={`rltl-${idx}}`} className="p-cmsi-informacion-interes-s"><span className="bold">{l.titulo ? `${l.titulo[t("lang")]}:`: ""}</span> {l.texto[t("lang")]}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {
                                                s.listaImagen.detalleListas &&
                                                <p className="subp-cmsi-informacion-interes-s">{s.listaImagen.detalleListas[t("lang")]}</p>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row key={`rli-${idx}}`} className="justify-content-center row-cmsi-informacion-interes-content">
                                <Col className="no-margin" lg={3} sm={3} xs={3}>
                                    <img alt="img-t-l" className="ii-img-t-l" src={s.listaImagen.imagenTL} />
                                    <img alt="img-b-l" className="ii-img-b-l" src={s.listaImagen.imagenBL} />
                                </Col>
                                <Col className="no-margin" lg={3} sm={3} xs={3}>
                                    <img alt="img-c" className="ii-img-c" src={s.listaImagen.imagenC} />
                                </Col>
                                <Col className="no-margin" lg={3} sm={3} xs={3}>
                                    <img alt="img-r" className="ii-img-r" src={s.listaImagen.imagenR} />
                                </Col>
                            </Row>
                        </>
                        )
                    }
                    else if (s.listaImagen && s.listaImagen.formato === 2) {
                        return (
                            <Row key={`rl2-${idx}`} className="justify-content-center">
                                <Col lg={11} sm={11} xs={11}>
                                    <Row>
                                        <Col lg={6}>
                                            <ul>
                                                {
                                                    s.listaImagen.listas && s.listaImagen.listas.length > 0 &&
                                                    s.listaImagen.listas.map((l) => {
                                                        return (
                                                            <li key={`rltl2-${idx}`} className="p-cmsi-informacion-interes-s"><span className="bold">{l.titulo ? `${l.titulo[t("lang")]}:`: ""}</span> {l.texto[t("lang")]}</li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {
                                                s.listaImagen.detalleListas &&
                                                <p className="subp-cmsi-informacion-interes-s">{s.listaImagen.detalleListas[t("lang")]}</p>
                                            }
                                        </Col>
                                        <Col lg={6}>
                                            <Row className="justify-content-center separador-cmsi-informacion-interes">
                                                <Col lg={11} sm={11} xs={11}>
                                                    <Row>
                                                        <Col lg={12} sm={12} xs={12} className="no-margin">
                                                            <img alt="img-2-t-l" className="ii-img-2-t-l" src={s.listaImagen.imagenTL} />
                                                            <img alt="img-2-t-r" className="ii-img-2-t-r" src={s.listaImagen.imagenTR} />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg={12} sm={12} xs={12} className="no-margin">
                                                            <img alt="img-2-b" className="ii-img-2-b" src={s.listaImagen.imagenB} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )
                    }
                    else if (s.parques) {
                        return (
                            <>
                                <Row key={`rtp-${idx}`} className="justify-content-center">
                                    <Col lg={11}>
                                        <p className="p-cmsi-informacion-interes-t row-centered">{t("nuestros-servicios.parenas-porvenir.info-interes.punta-arenas.sub-t2")}</p>
                                    </Col>
                                </Row>
                                <Row key={`rlp-${idx}`} className="justify-content-center">
                                    <Col lg={11}>
                                        <Row className="justify-content-center">
                                            {
                                                s.parques.listado && s.parques.listado.length > 0 &&
                                                s.parques.listado.map((p) => {
                                                    return (
                                                        <Col key={`rpll-${idx}`} lg={3} md={4} sm={4} xs={6}>
                                                            <div className="div-cmsi-informacion-interes-parque">
                                                                <img src={p.imagen} className="img-cmsi-informacion-interes-parque" alt={p.nombre}/>
                                                                <div className="link-cmsi-informacion-interes-parque" onClick={() => { window.location.href = p.link }}>
                                                                    {t("lang") === "es" && <p>{t("nuestros-servicios.parenas-porvenir.info-interes.punta-arenas.parque-a-1")}</p>}
                                                                    <p>{p.nombre}</p>
                                                                    {t("lang") === "en" && <p>{t("nuestros-servicios.parenas-porvenir.info-interes.punta-arenas.parque-a-2")}</p>}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                {
                                    s.parques.verMas && s.parques.verMas.length > 0 &&
                                    <Row key={`rvm-${idx}`} className="justify-content-center">
                                        <Col lg={11}>
                                            <p className="p-cmsi-informacion-interes-s row-centered">
                                            <span className="p-sub-cmsi-informacion-interes-s">{t("nuestros-servicios.parenas-porvenir.info-interes.porvenir.mas-info")}</span>
                                                {
                                                    s.parques.verMas.map((v, i) => {
                                                        return (
                                                            <>
                                                                {
                                                                    i > 0 &&
                                                                    <div key={`rvms-${idx}`} className="separador-cmsi-informacion-interes-s"></div>
                                                                }
                                                                <span key={`rvml-${idx}`} className="link-cmsi-informacion-interes" onClick={() => { window.location.href = v.link }}>{v.titulo[t("lang")]}</span>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                }
                            </>
                        )
                    }
                    else if (s.tips) {
                        return (
                            <>
                                {
                                    s.tips.titulo &&
                                    <Row key={`ratp-${idx}`} className="justify-content-center">
                                        <Col lg={11}>
                                            <p className="p-cmsi-informacion-interes-t row-centered">{s.tips.titulo[t("lang")]}</p>
                                        </Col>
                                    </Row>
                                }
                                {
                                    s.tips.texto &&
                                    <Row key={`ratp-${idx}`} className="justify-content-center">
                                        <Col lg={11}>
                                            <p className="p-cmsi-informacion-interes-s row-centered">{s.tips.texto[t("lang")]}</p>
                                        </Col>
                                    </Row>
                                }
                                {
                                    s.tips.verMas && s.tips.verMas.length > 0 &&
                                    <Row key={`ravm-${idx}`} className="justify-content-center">
                                        <Col lg={11}>
                                            <p className="p-cmsi-informacion-interes-s row-centered">
                                            <span className="p-sub-cmsi-informacion-interes-s">{s.tips.detalleVerMas ? s.tips.detalleVerMas[t("lang")] : ""} {t("nuestros-servicios.parenas-porvenir.info-interes.porvenir.mas-info")}</span>
                                                {
                                                    s.tips.verMas.map((v, i) => {
                                                        return (
                                                            <>
                                                                {
                                                                    i > 0 &&
                                                                    <div key={`ravms-${idx}`} className="separador-cmsi-informacion-interes-s"></div>
                                                                }
                                                                <span key={`ravml-${idx}`} className="link-cmsi-informacion-interes" onClick={() => { window.location.href = v.link }}>{v.titulo[t("lang")]}</span>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                }
                            </>
                        )
                    }
                    else {
                        return (<></>)
                    }
                })
              }
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={`/rutas/${code}`} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default InformacionInteres;
