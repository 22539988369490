import "./Chat.css";
import ChatIcon from "./src/chat-icon.svg";
import { useState } from "react";

function Chat({ clickOut, chatOpen } ) {
    
    return (
        <>
            {/*
                !chatOpen && 
                <div onClick={() => { clickOut(true, false, false, false, false) }} className="div-chat">
                    <img className="img-chat" alt="Chat" src={ChatIcon} />
                </div> */
            }
            {
                chatOpen
            }
        </>
    )
}

export default Chat;