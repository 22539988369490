import "./Loading.css";
import LoadingIcon from "./src/cargando.webp";

function Loading({ show }) {
    return (
        <>
            {
                show &&
                <div className="div-loading-admin">
                    <img className="img-loading-admin" src={LoadingIcon} />
                </div>
            }
        </>
    )
}

export default Loading;