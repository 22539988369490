import "./Itinerarios.css";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../components/EstadoCruce/EstadoCruce';
import Navegacion from "../components/Navegacion/Navegacion";
import VolverInicio from "../components/VolverInicio/VolverInicio";
import Banners from "../components/Banners/Banners";
import Footer from "../components/Footer/Footer";
import Chat from "../components/Chat/Chat";
import ImgItinerarios from "./src/itinerarios_1.png";
import moment from "moment";

import { useTranslation } from "react-i18next";


function Itinerarios() {
  const [t, i18n] = useTranslation("global");

  const [params] = useSearchParams(); //params.get("id")
  const [backImage, setBackImage] = useState({ imagen: ImgItinerarios });
  const [itinerarios, setItinerarios] = useState([]);

  const [cruces, setCruces] = useState([]);
  const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
  const [showCruce, setShowCruce] = useState(false);
  const [rutas, setRutas] = useState([
    {
        "id_tramo":1,
        "id_lugar":"5",
        "nombre_tramo":"Punta Arenas -> Porvenir",
        "nombre_corto":"PA-PV",
        "id_origen":"1",
        "id_destino":"2",
        "tramo_inverso":"2",
        "flex_trayecto":"3.T.PTES - B.CHILOTA"
    },
    {
        "id_tramo":2,
        "id_lugar":"5",
        "nombre_tramo":"Porvenir -> Punta Arenas",
        "nombre_corto":"PV-PA",
        "id_origen":"2",
        "id_destino":"1",
        "tramo_inverso":"1",
        "flex_trayecto":"4.B.CHILOTA - T.PTES"
    },
    {
        "id_tramo":3,
        "id_lugar":"6",
        "nombre_tramo":"Punta Arenas -> Puerto Williams",
        "nombre_corto":"PA-PW",
        "id_origen":"1",
        "id_destino":"3",
        "tramo_inverso":"4",
        "flex_trayecto":"5.T.PTES-P.WILLIAMS"
    },
    {
        "id_tramo":4,
        "id_lugar":"6",
        "nombre_tramo":"Puerto Williams -> Punta Arenas",
        "nombre_corto":"PW-PA",
        "id_origen":"3",
        "id_destino":"1",
        "tramo_inverso":"3",
        "flex_trayecto":"6.P.WILLIAMS-T.PTES"
    },
    {
        "id_tramo":5,
        "id_lugar":"1",
        "nombre_tramo":"Punta Delgada -> Bah\u00eda Azul",
        "nombre_corto":"PD-BA",
        "id_origen":"5",
        "id_destino":"6",
        "tramo_inverso":"6",
        "flex_trayecto":"1.P.DELGADA - B.AZUL"
    },
    {
        "id_tramo":6,
        "id_lugar":"1",
        "nombre_tramo":"Bah\u00eda Azul -> Punta Delgada",
        "nombre_corto":"BA-PD",
        "id_origen":"6",
        "id_destino":"5",
        "tramo_inverso":"5",
        "flex_trayecto":"2.B.AZUL - P.DELGADA"
    },
    {
        "id_tramo":7,
        "id_lugar":"3",
        "nombre_tramo":"R\u00edo Verde -> Isla Riesco",
        "nombre_corto":"RV-IR",
        "id_origen":"7",
        "id_destino":"8",
        "tramo_inverso":"8",
        "flex_trayecto":"10. RIO VERDE-PONSOM"
    },
    {
        "id_tramo":8,
        "id_lugar":"3",
        "nombre_tramo":"Isla Riesco -> R\u00edo Verde",
        "nombre_corto":"IR-RV",
        "id_origen":"8",
        "id_destino":"7",
        "tramo_inverso":"7",
        "flex_trayecto":"11.PONSOMBY-RIO VERD"
    },
    {
        "id_tramo":9,
        "id_lugar":"7",
        "nombre_tramo":"Punta Arenas -> Isla Magdalena - Punta Arenas",
        "nombre_corto":"PA-IM",
        "id_origen":"1",
        "id_destino":"9",
        "tramo_inverso":"9",
        "flex_trayecto":"14.ISLA MAGDALENA"
    },
    {
        "id_tramo":11,
        "id_lugar":"2",
        "nombre_tramo":"Bah\u00eda Punta Arenas",
        "nombre_corto":"PA",
        "id_origen":"1",
        "id_destino":"11",
        "tramo_inverso":"11",
        "flex_trayecto":"12.T.PTES-B.P.ARENAS"
    },
    {
        "id_tramo":20,
        "id_lugar":"20",
        "nombre_tramo":"Oficina",
        "nombre_corto":"OF",
        "id_origen":"1",
        "id_destino":"1",
        "tramo_inverso":"20",
        "flex_trayecto":"9.OFICINA"
    },
    {
        "id_tramo":21,
        "id_lugar":"21",
        "nombre_tramo":"Otras zonas - Pr\u00e1cticos",
        "nombre_corto":"OZ",
        "id_origen":"5",
        "id_destino":"5",
        "tramo_inverso":"21",
        "flex_trayecto":"8.OTRAS ZONAS"
    },
    {
        "id_tramo":24,
        "id_lugar":"24",
        "nombre_tramo":"Otras zonas - Navimag T",
        "nombre_corto":"OZ",
        "id_origen":"1",
        "id_destino":"1",
        "tramo_inverso":"24",
        "flex_trayecto":"8.OTRAS ZONAS"
    },
    {
        "id_tramo":25,
        "id_lugar":"25",
        "nombre_tramo":"Otras zonas - Navimag M",
        "nombre_corto":"OZ",
        "id_origen":"1",
        "id_destino":"1",
        "tramo_inverso":"25",
        "flex_trayecto":"8.OTRAS ZONAS"
    },
    {
        "id_tramo":30,
        "id_lugar":"99",
        "nombre_tramo":"Otras zonas",
        "nombre_corto":"OZ",
        "id_origen":"1",
        "id_destino":"1",
        "tramo_inverso":"30",
        "flex_trayecto":"8.OTRAS ZONAS"
    },
    {
        "id_tramo":31,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Natales",
        "nombre_corto":"PN",
        "id_origen":"41",
        "id_destino":"41","tramo_inverso":"31",
        "flex_trayecto":"20.PTO.NATALES"
    },
    {
        "id_tramo":41,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Natales -> Puerto Ed\u00e9n",
        "nombre_corto":"PN-PE",
        "id_origen":"41",
        "id_destino":"51",
        "tramo_inverso":"51",
        "flex_trayecto":"17.PTO.NATALES-EDEN"
    },
    {
        "id_tramo":42,
        "id_lugar":"4",
        "nombre_tramo":
        "Puerto Natales -> Caleta Tortel",
        "nombre_corto":"PN-TO",
        "id_origen":"41",
        "id_destino":"46",
        "tramo_inverso":"52",
        "flex_trayecto":"15.PTO.NATALES-TORTE"
    },
    {
        "id_tramo":43,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Natales -> Puerto Yungay",
        "nombre_corto":"PN-PY",
        "id_origen":"41",
        "id_destino":"56",
        "tramo_inverso":"53",
        "flex_trayecto":"16.PTO.NATALES-YUNGA"
    },
    {
        "id_tramo":44,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Ed\u00e9n -> Caleta Tortel",
        "nombre_corto":"PE-TO",
        "id_origen":"51",
        "id_destino":"46",
        "tramo_inverso":"54",
        "flex_trayecto":"18.PTO.EDEN-TORTEL"
    },
    {
        "id_tramo":45,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Ed\u00e9n -> Puerto Yungay",
        "nombre_corto":"PE-PY",
        "id_origen":"51",
        "id_destino":"56",
        "tramo_inverso":"55",
        "flex_trayecto":"19.PTO.EDEN-YUNGAY"
    },
    {
        "id_tramo":46,
        "id_lugar":"4",
        "nombre_tramo":"Caleta Tortel -> Puerto Yungay",
        "nombre_corto":"TO-PY",
        "id_origen":"46",
        "id_destino":"56",
        "tramo_inverso":"56",
        "flex_trayecto":"23.TORTEL-YUNGAY"
    },
    {
        "id_tramo":51,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Ed\u00e9n -> Puerto Natales",
        "nombre_corto":"PE-PN",
        "id_origen":"51",
        "id_destino":"41",
        "tramo_inverso":"41",
        "flex_trayecto":"17.PTO.NATALES-EDEN"
    },
    {
        "id_tramo":52,
        "id_lugar":"4",
        "nombre_tramo":"Caleta Tortel -> Puerto Natales",
        "nombre_corto":"TO-PN",
        "id_origen":"46",
        "id_destino":"41",
        "tramo_inverso":"42",
        "flex_trayecto":"15.PTO.NATALES-TORTE"
    },
    {
        "id_tramo":53,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Yungay -> Puerto Natales",
        "nombre_corto":"PY-PN",
        "id_origen":"56",
        "id_destino":"41",
        "tramo_inverso":"43",
        "flex_trayecto":"16.PTO.NATALES-YUNGA"
    },
    {
        "id_tramo":54,
        "id_lugar":"4",
        "nombre_tramo":"Caleta Tortel -> Puerto Ed\u00e9n",
        "nombre_corto":"TO-PE",
        "id_origen":"46",
        "id_destino":"51",
        "tramo_inverso":"44",
        "flex_trayecto":"18.PTO.EDEN-TORTEL"
    },
    {
        "id_tramo":55,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Yungay -> Puerto Ed\u00e9n",
        "nombre_corto":"PY-PE",
        "id_origen":"56",
        "id_destino":"51",
        "tramo_inverso":"45",
        "flex_trayecto":"19.PTO.EDEN-YUNGAY"
    },
    {
        "id_tramo":56,
        "id_lugar":"4",
        "nombre_tramo":"Puerto Yungay -> Caleta Tortel",
        "nombre_corto":"PY-TO",
        "id_origen":"56",
        "id_destino":"46",
        "tramo_inverso":"46",
        "flex_trayecto":"22.YUNGAY-TORTEL"
    }
]);
  
  useEffect(() => {

    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" && d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`https://us-central1-tabsacl-api.cloudfunctions.net/Tabsa/rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });

    let noviembre = [
        { "id":15813, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-02 16:00:00", "estado":"40", "zarpeOriginal":"2023-11-02 18:00:00", "arribo":"2023-11-02 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15377, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-04 15:00:00", "estado":"8", "zarpeOriginal":"2023-11-04 16:00:00", "arribo":"2023-11-04 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15902, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-05 23:00:00", "estado":"8", "zarpeOriginal":"2023-11-06 01:00:00", "arribo":"2023-11-06 04:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15908, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-07 13:00:00", "estado":"8", "zarpeOriginal":"2023-11-07 14:00:00", "arribo":"2023-11-07 17:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15814, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-09 16:00:00", "estado":"40", "zarpeOriginal":"2023-11-09 18:00:00", "arribo":"2023-11-09 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15378, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-11 15:00:00", "estado":"8", "zarpeOriginal":"2023-11-11 16:00:00", "arribo":"2023-11-11 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15815, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-16 16:00:00", "estado":"40", "zarpeOriginal":"2023-11-16 18:00:00", "arribo":"2023-11-16 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15379, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-18 15:00:00", "estado":"8", "zarpeOriginal":"2023-11-18 16:00:00", "arribo":"2023-11-18 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15903, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-19 23:00:00", "estado":"8", "zarpeOriginal":"2023-11-20 01:00:00", "arribo":"2023-11-20 04:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15909, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-21 13:00:00", "estado":"8", "zarpeOriginal":"2023-11-21 14:00:00", "arribo":"2023-11-21 17:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15816, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-23 19:45:00", "estado":"24", "zarpeOriginal":"2023-11-23 20:45:00", "arribo":"2023-11-23 23:45:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15380, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-11-26 15:00:00", "estado":"8", "zarpeOriginal":"2023-11-26 16:00:00", "arribo":"2023-11-26 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15817, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-11-30 16:00:00", "estado":"8", "zarpeOriginal":"2023-11-30 18:00:00", "arribo":"2023-11-30 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":false, "proximo":true }
    ];

    let diciembre = [
        { "id":15813, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-02 16:00:00", "estado":"40", "zarpeOriginal":"2023-12-02 18:00:00", "arribo":"2023-12-02 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15377, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-12-04 15:00:00", "estado":"8", "zarpeOriginal":"2023-12-04 16:00:00", "arribo":"2023-12-04 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15902, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-06 23:00:00", "estado":"8", "zarpeOriginal":"2023-12-07 01:00:00", "arribo":"2023-12-07 04:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15908, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-12-09 13:00:00", "estado":"8", "zarpeOriginal":"2023-12-09 14:00:00", "arribo":"2023-12-09 17:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15814, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-12 16:00:00", "estado":"40", "zarpeOriginal":"2023-12-12 18:00:00", "arribo":"2023-12-12 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15378, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-12-13 15:00:00", "estado":"8", "zarpeOriginal":"2023-12-13 16:00:00", "arribo":"2023-12-13 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15815, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-17 16:00:00", "estado":"40", "zarpeOriginal":"2023-12-17 18:00:00", "arribo":"2023-12-17 21:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15379, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-12-20 15:00:00", "estado":"8", "zarpeOriginal":"2023-12-20 16:00:00", "arribo":"2023-12-20 19:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15903, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-24 23:00:00", "estado":"8", "zarpeOriginal":"2023-12-25 01:00:00", "arribo":"2023-12-25 04:00:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
        { "id":15909, "nave":"Yaghan", "id_tramo":"4", "origen":"Puerto Williams", "destino":"Punta Arenas", "presentacion":"2023-12-26 13:00:00", "estado":"8", "zarpeOriginal":"2023-12-26 14:00:00", "arribo":"2023-12-26 17:00:00", "nombre_embarcadero":"Williams", "pasado":true, "proximo":false },
        { "id":15816, "nave":"Yaghan", "id_tramo":"3", "origen":"Punta Arenas", "destino":"Puerto Williams", "presentacion":"2023-12-31 19:45:00", "estado":"24", "zarpeOriginal":"2023-12-31 20:45:00", "arribo":"2023-12-31 23:45:00", "nombre_embarcadero":"Terminal Tres Puentes", "pasado":true, "proximo":false },
    ];

    let noviembre_reorganizado = [];
    noviembre.map((item, index) => {
        let exists = false;

        noviembre_reorganizado.map((itemN, indexN) => {
            if (itemN.id_tramo === item.id_tramo) {
                exists = true;
                
                itemN.info.push(item)
            }
        })

        if (!exists) {
            noviembre_reorganizado.push({
                mes: "Nov",
                mes_fecha: "2023-11",
                id_tramo: item.id_tramo,
                origen: item.origen,
                destino: item.destino,
                info: [item]
            })
        }
    })

    let diciembre_reorganizado = [];
    diciembre.map((item, index) => {
        let exists = false;

        diciembre_reorganizado.map((itemN, indexN) => {
            if (itemN.id_tramo === item.id_tramo) {
                exists = true;
                
                itemN.info.push(item)
            }
        })

        if (!exists) {
            diciembre_reorganizado.push({
                mes: "Dec",
                mes_fecha: "2023-12",
                id_tramo: item.id_tramo,
                origen: item.origen,
                destino: item.destino,
                info: [item]
            })
        }
    })

    setItinerarios([].concat(noviembre_reorganizado).concat(diciembre_reorganizado));

  }, []);



  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

  return (
    <div className="Itinerarios">
      <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
      <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
        <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
      </div>
      <div className="div-itinerarios-s" clickOut={() => { showItems(false, false, false, false, false) }}>
        <img className="img-itinerarios-s" alt={"itinerarios"} src={backImage.imagen} />
      </div>
      <div className="div-itinerarios-body" clickOut={() => { showItems(false, false, false, false, false) }}>
        <Container>
          <Row className="justify-content-center">
            <Col lg={11}>
              <p className="ftitle-itinerario-s">{t("itinerarios.itinerarios")} <span className="ftitle-itinerario-s-bold">PUNTA ARENAS - PUERTO WILLIAMS</span></p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10}>
              <Row className="row-itinerarios-s">
                {
                    itinerarios.map((itinerario) => {
                        return (
                            <Col lg={6}>
                                <Row className="justify-content-center">
                                    <Col className="block-itinerario-s" lg={10}>
                                        <Row>
                                            <Col className="backtitle-itinerario-s" lg={12}>
                                                <p className="title-itinerario-s">{t(`months.${itinerario.mes}`).toUpperCase()}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="backsubtitle-itinerario-s" lg={12}>
                                                <p className="subtitle-itinerario-s">{itinerario.origen} - {itinerario.destino}</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="no-margin" lg={6}>
                                                <div className="back-horario-s margin-itinerario-r">
                                                    <p className="p-itinerario-s">{t(`itinerarios.salida`)}</p>
                                                </div>
                                            </Col>
                                            <Col className="no-margin" lg={6}>
                                                <div className="back-horario-s margin-itinerario-l">
                                                    <p className="p-itinerario-s">{t(`itinerarios.llegada`)}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        {
                                            itinerario.info.map((item, index) => {
                                                return (
                                                    <Row className={`${index > 0 ? "row-itinerario-s" : ""}`}>
                                                        <Col className="" lg={3}>
                                                            <p className="col-itinerario-l">{moment(item.zarpeOriginal).format("DD-MM-yyyy")}</p>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <p className="col-itinerario-r">{t(`days.${moment(item.zarpeOriginal).format("ddd")}`)} | {moment(item.zarpeOriginal).format("HH:mm")}</p>
                                                        </Col>
                                                        <Col className="" lg={3}>
                                                            <p className="col-itinerario-l">{moment(item.arribo).format("DD-MM-yyyy")}</p>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <p className="col-itinerario-r">{t(`days.${moment(item.arribo).format("ddd")}`)} | {moment(item.arribo).format("HH:mm")}</p>
                                                        </Col>
                                                    </Row>
                                                )
                                            })
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        )
                    })
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Chat clickOut={showItems} chatOpen={chatOpen}/>
      <Banners clickOut={showItems} />
      <Footer clickOut={showItems} />
      <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
    </div>
  );
}

export default Itinerarios;
