import "./Cruces.css";
import { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import EstadoCruce from '../components/EstadoCruce/EstadoCruce';
import Navegacion from "../components/Navegacion/Navegacion";
import VolverInicio from "../components/VolverInicio/VolverInicio";
import Footer from "../components/Footer/Footer";
import Chat from "../components/Chat/Chat";
import moment from "moment";
import LogoColor from "./src/logo-tabsa-color.png";
import IconoEstadoCancelado from "./src/EstadoCruceCancelado.svg";
import IconoEstadoDemora from "./src/EstadoCruceDemora.svg";
import IconoEstadoEnViaje from "./src/EstadoCruceEnViaje.svg";
import BackgroundImage from "./src/cruces_background.png";

import { useTranslation } from "react-i18next";

function Cruces() {
  const [t, i18n] = useTranslation("global");

  const [cruces, setCruces] = useState([]);
  const [crucesA, setCrucesA] = useState([]);
  const [crucesB, setCrucesB] = useState([]);
  const [crucesLugares, setCrucesLugares] = useState([]);
  const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
  const [showCruce, setShowCruce] = useState(false);
  const [firstDate, setFirstDate] = useState(moment(new Date()).format("DD-MM-yyyy"));
  const [secondDate, setSecondDate] = useState(moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy"));
  
  useEffect(() => {

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0],
                    hora_embarque: d.presentacion.split(" ")[1].substring(0, 5), 
                    fecha_embarque: d.presentacion.split(" ")[0],
                    fecha_busqueda: moment(new Date()).format("yyyy-MM-DD")
                }))
                setCruces(newCruces);
                setCrucesA(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0],
                                hora_embarque: d.presentacion.split(" ")[1].substring(0, 5), 
                                fecha_embarque: d.presentacion.split(" ")[0],
                                fecha_busqueda: moment(new Date().setDate(new Date().getDate() + 1)).format("yyyy-MM-DD")
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);
                            setCrucesB(newSecondCruces);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    })

    fetch(`https://api-cruce.tabsa.cl/api/cruce/suspension`, { method: "GET" }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          setCrucesLugares(data.data.map((d) => (
            { 
              estado: d.estado_detalle.toLowerCase(), 
              lugar: d.lugar,
              codigo: d.estado,
              timestamp_suspension: d.timestamp_suspension,
              timestamp_reanudacion: d.timestamp_reanudacion,
              observaciones: d.observaciones
            }
          )))
        })
      }
      else {
        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
      }
    })

  }, [firstDate, secondDate]);

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

  return (
    <div className="Cruces">
      <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
      <div className={`d-none d-md-block slider-top-shadow${!showCruce ? "-off": ""}`}>
        <Navegacion clickOut={showItems} rutas={[]} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
      </div>
      <div className="div-cruces-s d-none d-md-block" clickOut={() => { showItems(false, false, false, false, false) }}>
        <img className="img-cruces-s" alt="cruces" src={BackgroundImage} />
      </div>
      <div className="div-cruces-body" clickOut={() => { showItems(false, false, false, false, false) }}>
        <Container>
          <Row className="justify-content-center d-block d-md-none align-center">
            <Col lg={6} sm={12} xs={12}>
              <img alt="logo" className="logo-color-xs" src={LogoColor} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={11} sm={11} xs={11}>
                <p className="title-cruces-s">{t("cruces.titulo")}</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={11}>
              <Row className="row-cruces-s">
                <Col lg={12}>
                  <table className="table-cruces-s">
                    <thead>
                      <tr>
                        <td colSpan={2}>
                          {t("cruces.lugar")}
                        </td>
                        <td className="align-center no-margin" colSpan={2}>
                          {t("cruces.estado")}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        crucesLugares && crucesLugares.map((c, i) => {
                          return (
                            <tr className={i > 0 ? "tr-border" : ""}>
                              <td className="bold" colSpan={2}>
                                {c.lugar}
                              </td>
                              <td className="align-right">
                                <img className="icono-estado" alt="Estado cruce" src={(c.estado === "cancelado" || c.estado === "suspendido") ? IconoEstadoCancelado : (c.estado === "demora") ? IconoEstadoDemora : IconoEstadoEnViaje} />
                              </td>
                              <td className="align-left bold">
                                {c.estado.toUpperCase()}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
                <Col lg={6}>
                </Col>
              </Row>
              <Row className="row-cruces-s">
                <Col lg={12}>
                  <p className="subtitle-cruces-s">{t("cruces.programados")} {firstDate}</p>
                </Col>
                <Col lg={12}>
                  <table className="table-cruces-s">
                    <thead>
                      <tr>
                        <td>
                          {t("cruces.origen")}
                        </td>
                        <td>
                          {t("cruces.destino")}
                        </td>
                        <td>
                          {t("cruces.hora-embarque")}
                        </td>
                        <td>
                          {t("cruces.hora-zarpe")}
                        </td>
                        <td className="align-center no-margin" colSpan={2}>
                          {t("cruces.estado")}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        crucesA && crucesA.map((c, i) => {
                          return (
                            <tr className={i > 0 ? "tr-border" : ""}>
                              <td className="bold">
                                {c.origen}
                              </td>
                              <td className="bold">
                                {c.destino}
                              </td>
                              <td>
                                {c.hora_embarque} {c.fecha_embarque !== c.fecha_busqueda ? `(${c.fecha_embarque.split("-")[2]}-${c.fecha_embarque.split("-")[1]})` : ""}
                              </td>
                              <td>
                                {c.hora}
                              </td>
                              <td className="align-right">
                                <img className="icono-estado" alt="Estado cruce" src={(c.estado === "cancelado" || c.estado === "suspendido") ? IconoEstadoCancelado : (c.estado === "demora") ? IconoEstadoDemora : IconoEstadoEnViaje} />
                              </td>
                              <td className="align-left bold">
                                {c.estado.toUpperCase()} 
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
              <Row className="row-cruces-s">
                <Col lg={12}>
                  <p className="subtitle-cruces-s">{t("cruces.programados")} {secondDate}</p>
                </Col>
                <Col lg={12}>
                  <table className="table-cruces-s">
                    <thead>
                      <tr>
                        <td>
                          {t("cruces.origen")}
                        </td>
                        <td>
                          {t("cruces.destino")}
                        </td>
                        <td>
                          {t("cruces.hora-embarque")}
                        </td>
                        <td>
                          {t("cruces.hora-zarpe")}
                        </td>
                        <td className="align-center no-margin" colSpan={2}>
                          {t("cruces.estado")}
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        crucesB && crucesB.map((c, i) => {
                          return (
                            <tr className={i > 0 ? "tr-border" : ""}>
                              <td className="bold">
                                {c.origen}
                              </td>
                              <td className="bold">
                                {c.destino}
                              </td>
                              <td>
                                {c.hora_embarque} {c.fecha_embarque !== c.fecha_busqueda ? `(${c.fecha_embarque.split("-")[2]}-${c.fecha_embarque.split("-")[1]})` : ""}
                              </td>
                              <td>
                                {c.hora}
                              </td>
                              <td className="align-right">
                                <img className="icono-estado" alt="Estado cruce" src={(c.estado === "cancelado" || c.estado === "suspendido") ? IconoEstadoCancelado : (c.estado === "demora") ? IconoEstadoDemora : IconoEstadoEnViaje} />
                              </td>
                              <td className="align-left bold">
                                {c.estado.toUpperCase()}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Chat clickOut={showItems} chatOpen={chatOpen}/>
      <Footer clickOut={showItems} />
      <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
    </div>
  );
}

export default Cruces;
