import "./CRVIRItinerario.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../../components/Navegacion/Navegacion";
import VolverInicio from "../../../components/VolverInicio/VolverInicio";
import Chat from "../../../components/Chat/Chat";
import Footer from "../../../components/Footer/Footer";
import LeftArrow from "./src/left.svg";
import RightArrow from "./src/right.svg";
import LogoColor from "./src/logo-tabsa-color.png";
import InfoBlue from "./src/info_blue.svg";
import InfoYellow from "./src/info_yellow.svg";

import moment from "moment";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

function CRVIRItinerario() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [ruta, setRuta] = useState({});

    const [idaA, setIdaA] = useState({ data: [] });
    
    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
      fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta/crioverde-islariesco`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRuta(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
      fetch(`https://api-cruce.tabsa.cl/api/tramos`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
      let fechaA = new Date();
      fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaA).format("YYYY-MM")}/7`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {

            if (data && data.data) {
              console.log(data)
            }
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="CRVIRItinerario">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-crvir-itinerario-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
              ruta.imgBack &&
              <img className="img-crvir-itinerario-s" alt={"crvir-itinerario"} src={ruta.imgBack} />
            }
          </div>
          <div className="div-crvir-itinerario-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <p className="title-crvir-itinerario-s">{t("nuestros-servicios.crioverde-islariesco.itinerario.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <div className="row-col-titulo">
                    {t("nuestros-servicios.crioverde-islariesco.itinerario.subtitulo")}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={10}>
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <Row className="justify-content-start">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.lunes")} 
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ width: "45%" }}>
                                  08:10
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:00
                                </td>
                              </tr>
                              <tr>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 10:20  
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                  09:00 <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  11:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  11:00
                                </td>
                              </tr>
                              <tr>
                              <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                              <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 14:30  
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                  13:30 <span><img src={InfoBlue} className="icon_info" alt="left" /> </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  15:10
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  19:20
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row className="justify-content-center">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.martes")} 
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ width: "45%" }}>
                                  09:00
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  12:40
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  12:20
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  16:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  15:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  17:10
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  20:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row className="justify-content-end">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.miercoles")} 
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style={{ width: "45%" }}>
                                  08:10
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:00
                                </td>
                              </tr>
                              <tr>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 10:20  
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                  09:00 <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  11:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  11:00
                                </td>
                              </tr>
                              <tr>
                              <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                              <span><img src={InfoBlue} className="icon_info" alt="left" /> </span> 14:30  
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")} style={{color: "#0684c6"}}>
                                  13:30 <span><img src={InfoBlue} className="icon_info" alt="left" /> </span>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  15:10
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  19:20
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:00
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <Row className="justify-content-start">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.jueves")} 
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td style={{ width: "45%" }}>
                                  09:00
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  12:40
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  12:20
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  16:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  15:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  17:10
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  20:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row className="justify-content-center">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.viernes")}  
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ width: "45%" }}>
                                  09:00
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  13:40
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  13:20
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  16:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  15:40
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:30
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  17:10
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  20:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={4}>
                      <Row className="justify-content-end">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li3")}>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.sabado")}  <span style={{color: "#0684c6"}}><img src={InfoYellow} style={{width:25}}/> </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td style={{ width: "45%" }}>
                                  09:00 
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td style={{ width: "45%" }}>
                                  08:50 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  12:00 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  11:40 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  13:30 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  13:00 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  15:00 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  14:50 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  17:40 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  17:30 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  20:00 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50 
                                </td>
                              </tr>
                            
                              {/* 
                              <tr className="no-border up">
                                <td>
                                  20:00
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50
                                </td>
                              </tr>
                              <tr className="no-border up">
                                <td colspan="4">Viaje exclusivo y gratuito para residentes</td>
                              </tr>

                              */}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={4}>
                      <Row className="justify-content-start">
                        <Col lg={11}>
                          <table className="crvir-itinerario-table">
                            <thead title={t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li3")}>
                              <tr>
                                <td colSpan={4}>
                                  <div className="row-col-subtitulo">
                                    {t("days.domingo")}  <span style={{color: "#0684c6"}}><img src={InfoYellow} style={{width:25}}/> </span>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    V. Ponsomby -<br className="d-none d-lg-block" />Isla Riesco 
                                  </div>
                                </td>
                                <td colSpan={2}>
                                  <div className="row-col-via">
                                    Isla Riesco -<br className="d-none d-lg-block" />V. Ponsomby 
                                  </div>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  09:10 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  09:00 
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  12:10 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  12:00 
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  17:00 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  16:50 
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  20:00 
                                </td>
                                <td>
                                  <img src={RightArrow} className="img-icon-crvir-itinerario" alt="right" />
                                </td>
                                <td>
                                  <img src={LeftArrow} className="img-icon-crvir-itinerario" alt="left" />
                                </td>
                                <td>
                                  19:50 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* 
                          <p className="p-crvir-itinerario-s"><span className="bold">{t("nuestros-servicios.crioverde-islariesco.itinerario.detalle-1")}</span> {t("nuestros-servicios.crioverde-islariesco.itinerario.detalle-2")}</p>
                          */}
                          </Col>
                      </Row>
                    </Col>

                    <Col lg={8}>
                      <Row className="justify-content-center">
                        <Col lg={12}>
                          <p className="subtitle-crvir-itinerario-s">
                            {t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.titulo")} 
                          </p>
                          <ul>
                            <li className="p-crvir-itinerario-s">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li1")}</li>
                            <li className="p-crvir-itinerario-s"><span style={{color: "#0684c6"}}><img src={InfoBlue} style={{width:25}} /> </span> {t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li2")}</li>
                            <li className="p-crvir-itinerario-s"><span style={{color: "#0684c6"}}><img src={InfoYellow} style={{width:25}}/> </span>  {t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li3")}</li>
                            <li className="p-crvir-itinerario-s">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-1.li4")}</li>
                          </ul>
                        </Col>
                        
                      </Row>

                    </Col>
                  </Row>

                  <Row className="justify-content-center">
                    <Col lg={{ span: 6}}>
                          <div className="consideraciones-crvir-itinerario">
                          <p className="title">
                            {t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-2.titulo")} 
                          </p>
                          <ul>
                            <li className="p">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-2.li1")}</li>
                            <li className="p">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-2.li2")}</li>
                            <li className="p">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-2.li3")}</li>
                            <li className="p">{t("nuestros-servicios.crioverde-islariesco.itinerario.tabla-2.li4")}</li>
                          </ul>
                          </div>
                    </Col>

                        <Col lg={6}>
                          <table className="crvir-itinerario-table-w">
                            <tr>
                              <td>
                                <div className="border-right">
                                  <p className="subtitle-crvir-itinerario-s title">PUNTA ARENAS</p>
                                  <p className="p-crvir-itinerario-s subtitle">Juan Williams 06450<br />Embarcadero Tres Puentes</p>
                                </div>
                              </td>
                              <td>
                                <div className="border-right">
                                  <p className="subtitle-crvir-itinerario-s title">PUERTO NATALES</p>
                                  <p className="p-crvir-itinerario-s subtitle">Av. Costanera<br />Pedro Montt 605</p>
                                </div>
                              </td>
                              <td>
                                <div>
                                  <p className="subtitle-crvir-itinerario-s title">PORVENIR</p>
                                  <p className="p-crvir-itinerario-s subtitle">Señoret 831</p><br />
                                </div>
                              </td>
                            </tr>
                          </table>
                        </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/rutas/crioverde-islariesco"} Texto={"volver-inicio.servicios"} />

        </div>
      );
}

export default CRVIRItinerario;
