import "./Ejecutivos.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Banners from "../../components/Banners/Banners";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgEjecutivos from "./src/ejecutivos_1.jpg";
import ImgCristobalKulczewski from "./src/cristobal-kulczewski.jfif";
import ImgAlejandroAlmonacid from "./src/alejandro-almonacid.jfif";
import ImgEdmundoVillarroel from "./src/edmundo-villarroel.jfif";
import ImgRicardoGomez from "./src/ricardo-gomez.png"; 
import moment from "moment";

import { useTranslation } from "react-i18next";

function Ejecutivos() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="Ejecutivos">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-ejecutivos-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-ejecutivos-s" alt={"ejecutivos"} src={ImgEjecutivos} />
          </div>
          <div className="div-ejecutivos-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-ejecutivos-s">
                <Col lg={11}>
                    <p className="title-ejecutivos-s">{t("nosotros.ejecutivos.titulo")}</p>
                </Col>
              </Row>
            </Container>
            <Row>
                <Col className="backrow-ejecutivo-s" lg={12}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={11} md={12}>
                                <Row className="justify-content-center row-ejecutivos-s">
                                    <Col lg={{ span: 8, order: 1 }} md={{ span: 8, order: 1 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }}>
                                        <p className="title-perfil-s">Cristóbal Kulczewski</p>
                                        <div className="under-title-perfil-s"></div>
                                        <p className="p-perfil-s">{t("nosotros.ejecutivos.gerente-general.p1")}</p>
                                        <p className="p-perfil-s">{t("nosotros.ejecutivos.gerente-general.p2")}</p>
                                    </Col>
                                    <Col lg={{ span: 4, order: 2 }} md={{ span: 4, order: 2 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                                        <img className="img-perfil-s" alt="Cristobal Kulczewski" src={ImgCristobalKulczewski} />
                                        <div className="bloque-nombre-s">
                                            <p className="nombre-perfil-s">{t("nosotros.ejecutivos.gerente-general.titulo")}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="backrow-ejecutivo-s-b" lg={12}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={11} md={12}>
                                <Row className="justify-content-center row-ejecutivos-s">
                                    <Col lg={{ span: 4, order: 1 }} md={{ span: 4, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                                        <img className="img-perfil-s" alt="Alejandro Almonacid" src={ImgAlejandroAlmonacid} />
                                        <div className="bloque-nombre-s">
                                            <p className="nombre-perfil-s">{t("nosotros.ejecutivos.gerente-operaciones.titulo")}</p>
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 8, order: 2 }} md={{ span: 8, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }}>
                                        <p className="title-perfil-s-b">Alejandro Almonacid</p>
                                        <div className="under-title-perfil-s-b"></div>
                                        <p className="p-perfil-s-b">{t("nosotros.ejecutivos.gerente-operaciones.p1")}</p>
                                        <p className="p-perfil-s-b">{t("nosotros.ejecutivos.gerente-operaciones.p2")}</p>
                                        <p className="p-perfil-s-b">{t("nosotros.ejecutivos.gerente-operaciones.p3")}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="backrow-ejecutivo-s" lg={12}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={11} md={12}>
                                <Row className="justify-content-center row-ejecutivos-s">
                                    <Col lg={{ span: 8, order: 1 }} md={{ span: 8, order: 1 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }}>
                                        <p className="title-perfil-s">Edmundo Villarroel</p>
                                        <div className="under-title-perfil-s"></div>
                                        <p className="p-perfil-s">{t("nosotros.ejecutivos.gerente-mantenimiento.p1")}</p>
                                        <p className="p-perfil-s">{t("nosotros.ejecutivos.gerente-mantenimiento.p2")}</p>
                                    </Col>
                                    <Col lg={{ span: 4, order: 2 }} md={{ span: 4, order: 2 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                                        <img className="img-perfil-s" alt="Edmundo Villarroel" src={ImgEdmundoVillarroel} />
                                        <div className="bloque-nombre-s">
                                            <p className="nombre-perfil-s">{t("nosotros.ejecutivos.gerente-mantenimiento.titulo")}</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Col className="backrow-ejecutivo-s-b" lg={12}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={11} md={12}>
                                <Row className="justify-content-center row-ejecutivos-s">
                                    <Col lg={{ span: 4, order: 1 }} md={{ span: 4, order: 1 }} sm={{ span: 12, order: 1 }} xs={{ span: 12, order: 1 }}>
                                        <img className="img-perfil-s" alt="Ricardo Gomez" src={ImgRicardoGomez} />
                                        <div className="bloque-nombre-s">
                                            <p className="nombre-perfil-s">{t("nosotros.ejecutivos.gerente-finanzas.titulo")}</p>
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 8, order: 2 }} md={{ span: 8, order: 2 }} sm={{ span: 12, order: 2 }} xs={{ span: 12, order: 2 }}>
                                        <p className="title-perfil-s-b">Ricardo Gómez</p>
                                        <div className="under-title-perfil-s-b"></div>
                                        <p className="p-perfil-s-b">{t("nosotros.ejecutivos.gerente-finanzas.p1")}</p>
                                        <p className="p-perfil-s-b">{t("nosotros.ejecutivos.gerente-finanzas.p2")}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default Ejecutivos;
