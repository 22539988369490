import "./AdminServicios.css";
import { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button, Card } from "react-bootstrap";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";
import AdminNavbar from "../../components/navbar/AdminNavbar";

function AdminServicios() {
    const [usuario, setUsuario] = useState(sessionStorage.getItem("admin-user"));

    if (!usuario || usuario === "") {
        window.location.href = "/admincms/login";
    }

    const [showPreview, setShowPreview] = useState(false);
    const [showLoading, setShowLoading] = useState(true);
    const [showEdicion, setShowEdicion] = useState({ nuevo: true, show: false });
    const [servicios, setServicios] = useState([]);
    const [servicio, setServicio] = useState({ code: "", contenido: [], cardTitulo: { es: "", en: "" }, cardDetalle: { es: "", en: "" }, navbar: { es: "", en: "" } })

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_BASEURL}/servicios/servicios`, { method: "GET" }).then((response) => {
            if (response.ok) {
                response.json().then(data => {
                    setServicios(data.data);
                    setShowLoading(false);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        })
    }, [])

    return (
        <>
            <AdminNavbar />
            <Loading show={showLoading} />
            <Container>
                {
                    !showEdicion.show && !showPreview &&
                    <Row className="justify-content-center content-admin-carrousel">
                        <Col lg={11}>
                            <Row className="justify-content-center">
                                <Col lg={10}>
                                    <p className="p-titulo-admin">Servicios</p>
                                </Col>
                                <Col lg={2}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowEdicion({ nuevo: true, show: true });
                                        setServicio({ code: "", contenido: [], cardTitulo: { es: "", en: "" }, cardDetalle: { es: "", en: "" }, navbar: { es: "", en: "" } })
                                    }}>Crear nuevo</Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <table className="table-admin-servicios">
                                        <thead>
                                            <tr>
                                                <td>Código</td>
                                                <td>Título (ES)</td>
                                                <td>Título (EN)</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            servicios.map((s, i) => (
                                                <tr key={`tserv-${i}`} className={`${i > 0 ? "tr-row": ""}`}>
                                                    <td className="bold">{s.code}</td>
                                                    <td>{s.titulo.es}</td>
                                                    <td>{s.titulo.en}</td>
                                                    <td>
                                                        <Button className="btn-admin-editar" onClick={() => {
                                                            setShowLoading(true);
                                                            setShowEdicion({ nuevo: false, show: true });
                                                            
                                                            fetch(`${process.env.REACT_APP_API_BASEURL}/servicios/servicio/${s.code}`, { method: "GET" }).then((response) => {
                                                                if (response.ok) {
                                                                    response.json().then(data => {
                                                                        setServicio(data.data);
                                                                        setShowLoading(false);
                                                                    })
                                                                }
                                                                else {
                                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                }
                                                            })

                                                        }}>Editar</Button>
                                                    </td>
                                                    <td>
                                                        <Button className="btn-admin-eliminar" onClick={() => {
                                                            Swal.fire({
                                                                icon: "info",
                                                                title: "¿Estás seguro de eliminar el servicio?",
                                                                showDenyButton: true,
                                                                confirmButtonText: "Eliminar",
                                                                denyButtonText: "Cancelar"
                                                            }).then(alResponse => {
                                                                if (alResponse.isConfirmed) {

                                                                    setShowLoading(true);

                                                                    fetch(`${process.env.REACT_APP_API_BASEURL}/servicios/servicio`, { 
                                                                        method: "DELETE",
                                                                        headers: {
                                                                            "Content-Type": "application/json"
                                                                        },
                                                                        body: JSON.stringify({ code: s.code, ultimoUsuario: usuario })
                                                                    }).then((response) => {
                                                                        if (response.ok) {
                                                                            response.json().then(data => {
                                                                                setServicios(servicios.filter(t => t.code !== s.code))

                                                                                Swal.fire({
                                                                                    icon: "success",
                                                                                    title: "Eliminado correctamente",
                                                                                    text: "Puedes visualizar el servicio en el sitio",
                                                                                    footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                                                })
                                                                                .then((alResponse) => {
                                                                                    setShowLoading(false);
                                                                                })
                                                                            })
                                                                        }
                                                                        else {
                                                                            Swal.fire({
                                                                                icon: "error",
                                                                                title: "No se pudo guardar",
                                                                                text: "Favor contacta con el equipo de desarrollo."
                                                                            })
                                                                            .then((alResponse) => {
                                                                                setShowLoading(false);
                                                                            })
                                                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                        }
                                                                    })
                                                                }
                                                                else if (alResponse.isDenied) {
                                                                }
                                                            })
                                                        }}>Eliminar</Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    showEdicion.show && !showPreview &&
                    <Row className="justify-content-center content-admin-carrousel">
                        <Col lg={11}>
                            <Row className="justify-content-center">
                                <Col lg={2}>
                                    <Button className="btn-admin-back" onClick={() => {
                                        setShowEdicion({ nuevo: true, show: false });
                                    }}>Volver</Button>
                                </Col>
                                <Col lg={8}>
                                </Col>
                                <Col lg={2}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowLoading(true);
                                        setShowEdicion({ nuevo: false, show: true });
                                        
                                        try 
                                        {
                                            fetch(`${process.env.REACT_APP_API_BASEURL}/servicios/servicio`, { 
                                                method: "POST",
                                                headers: {
                                                    "Content-Type": "application/json"
                                                },
                                                body: JSON.stringify({ ...servicio, ultimoUsuario: usuario })
                                            }).then((response) => {
                                                if (response.ok) {
                                                    response.json().then(data => {
                                                        setServicio(data.data);
                                                        Swal.fire({
                                                            icon: "success",
                                                            title: "Guardado correctamente",
                                                            text: "Puedes visualizar el servicio en el sitio",
                                                            footer: "<a href='/' target='_blank'>tabsa.cl</a>"
                                                        })
                                                        .then((alResponse) => {
                                                            setShowLoading(false);
                                                        })
                                                    })
                                                }
                                                else {
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: "No se pudo guardar",
                                                        text: "Favor contacta con el equipo de desarrollo."
                                                    })
                                                    .then((alResponse) => {
                                                        setShowLoading(false);
                                                    })
                                                    console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                }
                                            })
                                        }
                                        catch (error) {
                                            Swal.fire({
                                                icon: "error",
                                                title: "No se pudo guardar",
                                                text: `Favor contacta con el equipo de desarrollo. ${error}`
                                            })
                                            .then((alResponse) => {
                                                setShowLoading(false);
                                            })
                                        }
                                    }}>Guardar</Button>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Código</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        disabled={!showEdicion.nuevo}
                                        placeholder="(solo minúsculas y guiones medio)"
                                        value={servicio.code}
                                        onChange={(evt) => {
                                            setServicio({ ...servicio, code: evt.target.value })
                                        }}
                                    />
                                    {
                                        showEdicion.nuevo &&
                                        <p className="label-admin-recomendacion">* Ingresar solo letras minúsculas y guiones medio (-), este campo es usado para redireccionar al servicio en el sitio web.</p> 
                                    }
                                </Col>  
                                <Col lg={8}>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Background del servicio:</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`} 
                                        type="file" 
                                        id="txtFileL"
                                        placeholder="Sube la imagen background."
                                        accept="image/jpeg"
                                        onChange={(evt) => {
                                            if (evt.target.files.length > 0) {

                                                setShowLoading(true);

                                                let formData = new FormData();

                                                formData.append("pdf", evt.target.files[0]);
                                                
                                                fetch('https://recursos.tabsa.cl/api/file/save', {
                                                    method: "POST",
                                                    body: formData
                                                })
                                                .then(response => {
                                                    if (response.ok) {
                                                        response.json().then(data => {
                                                            setServicio({ ...servicio, imgBack: data.url })
                                                            setShowLoading(false);
                                                        })
                                                    }
                                                    else {
                                                        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                        setShowLoading(false);
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                })

                                            }
                                        }}
                                    />
                                    <p className="label-admin-recomendacion">* Resolución recomendada: 1920x737</p>   
                                </Col>
                                <Col lg={12}>
                                {
                                    servicio.imgBack &&
                                    <img className="img-services-s" alt={servicio.code} src={servicio.imgBack} />
                                }
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Barra de navegación</Form.Label>
                                    <br  />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (ES)</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        placeholder=""
                                        value={servicio.navbar.es}
                                        onChange={(evt) => {
                                            setServicio({ ...servicio, navbar: { es: evt.target.value, en: servicio.navbar.en } });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>
                                    <Form.Label className="label-admin" htmlFor="txtCodigo">Texto (EN)</Form.Label>
                                    <Form.Control 
                                        className={`form-field-admin`}  
                                        type="text" 
                                        id="txtCodigo"
                                        placeholder=""
                                        value={servicio.navbar.en}
                                        onChange={(evt) => {
                                            setServicio({ ...servicio, navbar: { es: servicio.navbar.es, en: evt.target.value } });
                                        }}
                                    />
                                </Col>
                                <Col lg={4}>

                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={12}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Card</Form.Label>
                                    <br  />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={8}>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen background."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setServicio({ ...servicio, cardImg: data.url });
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 686x513</p>   
                                        </Col> 
                                        <Col lg={6}>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Titulo (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={servicio.cardTitulo.es}
                                                onChange={(evt) => {
                                                    setServicio({ ...servicio, cardTitulo: { es: evt.target.value, en: servicio.cardTitulo.en } });
                                                }}
                                            />
                                        </Col>  
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Titulo (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={servicio.cardTitulo.en}
                                                onChange={(evt) => {
                                                    setServicio({ ...servicio, cardTitulo: { es: servicio.cardTitulo.es, en: evt.target.value } });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={servicio.cardDetalle.es}
                                                onChange={(evt) => {
                                                    setServicio({ ...servicio, cardDetalle: { es: evt.target.value, en: servicio.cardDetalle.en } });
                                                }}
                                            />
                                        </Col>  
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Descripción (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder=""
                                                value={servicio.cardDetalle.en}
                                                onChange={(evt) => {
                                                    setServicio({ ...servicio, cardDetalle: { es: servicio.cardDetalle.es, en: evt.target.value } });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>  
                                <Col className="col-card-servicio" lg={4}>
                                    <Card className="card-servicio">
                                        <Row>
                                            <Col lg={12}>
                                                {
                                                    servicio.cardImg &&
                                                    <img className="img-servicio" alt={servicio.cardTitulo.es} src={servicio.cardImg} />
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="div-detalle-servicio" lg={12}>
                                                {
                                                    servicio.cardTitulo && servicio.cardTitulo.es &&
                                                    <p className="p-nombre-servicio"><span className="p-b">{servicio.cardTitulo.es}</span></p>
                                                }
                                                {
                                                    servicio.cardDetalle && servicio.cardDetalle.es &&
                                                    <p className="p-detalle-servicio">{servicio.cardDetalle.es}</p>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={2}>
                                    <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Textos</Form.Label>
                                    <br  />
                                    <Button className="btn-admin-editar" onClick={() => {
                                        setServicio({ ...servicio, contenido: servicio.contenido.concat({ es: "", en: "" }) });
                                    }}>Agregar</Button>
                                    <br  />
                                    <br  />
                                </Col>
                                <Col lg={10}>
                                </Col>
                            </Row>
                            {
                                servicio.contenido.map((c, idx) => (
                                    <Row key={`row-texts-${idx}`} className="justify-content-center">
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Español (ES)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder="(solo minúsculas y guiones medio)"
                                                value={servicio.contenido[idx].es}
                                                onChange={(evt) => {

                                                    let newContenidos = servicio.contenido;
                                                    newContenidos[idx].es = evt.target.value
                                                    setServicio({ ...servicio, contenido: newContenidos });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={5}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Ingles (EN)</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`}  
                                                type="text" 
                                                id="txtCodigo"
                                                placeholder="(solo minúsculas y guiones medio)"
                                                value={servicio.contenido[idx].en}
                                                onChange={(evt) => {

                                                    let newContenidos = servicio.contenido;
                                                    newContenidos[idx].en = evt.target.value
                                                    setServicio({ ...servicio, contenido: newContenidos });
                                                }}
                                            />
                                        </Col>
                                        <Col lg={2}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Acciones</Form.Label>
                                            <br />
                                            <Button className="btn-admin-eliminar" onClick={() => {
                                                let newContenidos = servicio.contenido;
                                                newContenidos.splice(idx, 1);
                                                setServicio({ ...servicio, contenido: newContenidos });
                                            }}>Quitar</Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={6}>
                                    <Row>
                                        <Col>
                                            <Form.Label className="p-subtitulo-admin" htmlFor="txtCodigo">Imágenes</Form.Label>
                                            <br />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen izquierda</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen izquierda aquí."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setServicio({ ...servicio, imgL: data.url })
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 600x811</p>   
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Label className="label-admin" htmlFor="txtCodigo">Imágen derecha</Form.Label>
                                            <Form.Control 
                                                className={`form-field-admin`} 
                                                type="file" 
                                                id="txtFileL"
                                                placeholder="Sube la imagen izquierda aquí."
                                                accept="image/jpeg"
                                                onChange={(evt) => {
                                                    if (evt.target.files.length > 0) {

                                                        setShowLoading(true);

                                                        let formData = new FormData();

                                                        formData.append("pdf", evt.target.files[0]);
                                                        
                                                        fetch('https://recursos.tabsa.cl/api/file/save', {
                                                            method: "POST",
                                                            body: formData
                                                        })
                                                        .then(response => {
                                                            if (response.ok) {
                                                                response.json().then(data => {
                                                                    setServicio({ ...servicio, imgR: data.url })
                                                                    setShowLoading(false);
                                                                })
                                                            }
                                                            else {
                                                                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                                                setShowLoading(false);
                                                            }
                                                        })
                                                        .catch(error => {
                                                            console.log(error)
                                                        })

                                                    }
                                                }}
                                            />
                                            <p className="label-admin-recomendacion">* Resolución recomendada: 800x640</p>   
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <Col lg={12}>
                                        <br />
                                        <br />
                                        {
                                            servicio.imgL &&
                                            <img className="img-services-t-l" src={servicio.imgL} alt="t-l" />
                                        }
                                        {
                                            servicio.imgR &&
                                            <img className="img-services-t-r" src={servicio.imgR} alt="t-r" />
                                        }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowPreview(true);
                                    }}>Previsualizar</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
                {
                    showPreview &&
                    <>
                        <Col lg={12}>
                            <Row className="justify-content-center">
                                <Col lg={4}>
                                    <Button className="btn-admin" onClick={() => {
                                        setShowPreview(false);
                                    }}>Volver a edición</Button>
                                </Col>
                            </Row>
                        </Col>
                        <hr />
                        <div className="div-services-s">
                        {
                            servicio.imgBack &&
                            <img className="img-services-s" alt={servicio.code} src={servicio.imgBack} />
                        }
                        </div>
                        <div className="div-services-body">
                            <Container>
                                <Row className="justify-content-center row-services-content">
                                    <Col lg={11}>
                                        <Row>
                                            <Col lg={6}>
                                                <p className="title-services-s">{servicio.titulo.es}</p>
                                                {
                                                    servicio.contenido.map(c => (<p className="p-services-s">{c.es}</p>))
                                                }
                                            </Col>
                                            <Col lg={6}>
                                                <Row>
                                                    <Col lg={12}>
                                                    {
                                                        servicio.imgL &&
                                                        <img className="img-services-t-l" src={servicio.imgL} alt="transferencia de prácticos t-l" />
                                                    }
                                                    {
                                                        servicio.imgR &&
                                                        <img className="img-services-t-r" src={servicio.imgR} alt="transferencia de prácticos t-r" />
                                                    }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                }
            </Container>
        </>
    )
}

export default AdminServicios;