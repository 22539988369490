import "./PagarImprimir.css";
import PayIcon from "./src/pay-icon.svg";
import PrintIcon from "./src/print-icon.svg";
import PrimeraAngosturaIcon from "./src/primera-angostura-icon.svg";
import PinguinoIcon from "./src/pinguino.png";

import { Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

function PagarImprimir({ clickOut }) {
    const [t, i18n] = useTranslation("global");

    return (
        <>
            <div className="primera-angostura-button" onClick={() => {
                clickOut(false, false, false, false, false)
                window.open("https://preventa.tabsa.cl", "_blank");
            }}>
                <Row className="justify-content-center">
                    <Col>
                        <img className="primera-angostura-icon" src={PrimeraAngosturaIcon} alt={"Primera angostura"} />
                    </Col>
                </Row>
                <Row className="row-margin">
                    <Col>
                        <p className="primera-angostura-text">{t("pagar-imprimir.primera-angostura")}</p>
                    </Col>
                </Row>
            </div>

            <div className="print-button" onClick={() => {
                clickOut(false, false, false, false, false)
                window.open("https://milodon.tabsa.cl/", "_blank");
            }}>
                <Row className="justify-content-center">
                    <Col>
                        <img className="print-icon" src={PrintIcon} alt={"imprimir"} />
                    </Col>
                </Row>
                <Row className="row-margin">
                    <Col>
                        <p className="print-text">{t("pagar-imprimir.imprimir")}</p>
                    </Col>
                </Row>
            </div>

            <div className="pay-button" onClick={() => {
                clickOut(false, false, false, false, false)
                window.open("https://milodon.tabsa.cl/", "_blank");
            }}>
                <Row className="justify-content-center">
                    <Col>
                        <img className="pay-icon" src={PayIcon} alt={"pagar"} />
                    </Col>
                </Row>
                <Row className="row-margin">
                    <Col>
                        <p className="pay-text">{t("pagar-imprimir.pagar")}</p>
                    </Col>
                </Row>
            </div>
            
            <div className="magdalena-button" onClick={() => {
                clickOut(false, false, false, false, false)
                window.open("https://reservas.tabsa.cl/?fill=U2FsdGVkX19fwGgnRujG%20P4ZIFhEKNzDz6MdJ7HHgcw0umJEECQkhMPxdbQkbLLfsNQR1Li0o6zkNwZxugiXXg%3D%3D", "_blank");
            }}>
                <Row className="justify-content-center">
                    <Col>
                        <img className="magdalena-icon" src={PinguinoIcon} alt={"pagar"} />
                    </Col>
                </Row>
                <Row className="row-margin">
                    <Col>
                        <p className="magdalena-text"> ISLA MAGDALENA </p>
                    </Col>
                </Row>
            </div>
            
        </>
    )
}

export default PagarImprimir;
