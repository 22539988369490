import "./AdminNavbar.css";
import { Container, Nav, Navbar } from 'react-bootstrap';

function AdminNavbar() {
    return (
        
        <Navbar expand="lg" className="bg-body-tertiary admincms-navbar">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/admincms/banners">Banners</Nav.Link>
                        <Nav.Link href="/admincms/carrousel">Carrousel</Nav.Link>
                        <Nav.Link href="/admincms/modals">Modal</Nav.Link>
                        <Nav.Link href="/admincms/rutas">Rutas</Nav.Link>
                        <Nav.Link href="/admincms/servicios">Servicios</Nav.Link>
                        <Nav.Link href="/admincms/keys">Keys</Nav.Link>
                        <Nav.Link href="/admincms/tarjetas">Tarjetas</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default AdminNavbar;