import "./GoogleMap.css"
import * as React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";

const render = (status) => {
  return <h1>{status}</h1>;
};

const GoogleMap = ({ coords, mapZoom  }) => {
  const [zoom, setZoom] = React.useState(mapZoom);
  const [center, setCenter] = React.useState(
    { lat: (coords[0].lat + coords[coords.length - 1].lat) / 2, lng: (coords[0].lng + coords[coords.length - 1].lng) / 2 }
  );
  const [key, setKey] = React.useState(null);

  const onClick = (e) => {
  };

  const onIdle = (m) => {
    console.log("onIdle");
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  React.useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/keys/key/${"GoogleMaps"}`, { method: "GET" }).then((response) => {
      if (response.ok) {
        response.json().then(data => {
          setKey(data.data.key);
        })
      }
      else {
        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
      }
    })
  },  [])

  return (
    <div style={{ display: "flex", height: "100%", minHeight:"400px" }}>
      {
        key &&
        <Wrapper apiKey={key} render={render}>
          <Map
            center={center}
            onClick={onClick}
            onIdle={onIdle}
            zoom={zoom}
            style={{ flexGrow: "1", height: "100%", borderRadius: "16px" }}
          >
            <Marker position={coords[0]} />
            <Marker position={coords[coords.length - 1]} />
            <Line path={coords} geodesic={true} strokeColor={"#FFCD00"} strokeWeight={1} strokeOpacity={1} />
          </Map>
        </Wrapper>
      }
    </div>
  );
};

const Line = (options) => {
    const [line, setLine] = React.useState();

    React.useEffect(() => {
        if (!line) {
            setLine(new window.google.maps.Polyline());
        }
    
        return () => {
          if (line) {
            line.setMap(null);
          }
        };
      }, [line]);
    
      React.useEffect(() => {
        if (line) {
            line.setOptions(options);
        }
      }, [line, options]);

} 

const Map = ({ onClick, onIdle, children, style, ...options }) => {
    
  const ref = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  
  React.useEffect(() => {
    if (map) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(map, eventName)
      );
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);
  
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
  if (
    isLatLngLiteral(a) ||
    a instanceof window.google.maps.LatLng ||
    isLatLngLiteral(b) ||
    b instanceof window.google.maps.LatLng
  ) {
    return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
  }
  // TODO extend to other types
  // use fast-equals for other objects
  return deepEqual(a, b);
});

function useDeepCompareMemoize(value) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}


/*
function GoogleMap(coords) {
    const ref = useRef(null);
    const [map, setMap] = useState();

    useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }
    }, [ref, map])

    sessionStorage.setItem("coords", coords)

    useEffect(() => {

        window.iniciarMap = function() {
            const myLatLng1 = { lat: -53.2408, lng: -70.8656 };
            const myLatLng2 = { lat: -53.1131, lng: -70.8746 };
            
            const mapCoords = sessionStorage.getItem("coords");

            const map = new window.google.maps.Map(document.getElementById("map"), {
              center: myLatLng1,
              zoom: 10,
            });
        
            const marker1 = new window.google.maps.Marker({
              position: myLatLng1,
              map: map,
              title: "Punto 1",
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 8,
                fillColor: "#00FF00",
                fillOpacity: 1,
                strokeColor: "#000000",
                strokeWeight: 2,
              },
            });
        
            const marker2 = new window.google.maps.Marker({
              position: myLatLng2,
              map: map,
              title: "Punto 2",
              icon: {
                url: "https://cdn.icon-icons.com/icons2/520/PNG/512/Boat_icon-icons.com_52255.png", 
                scaledSize: new window.google.maps.Size(40, 40),
                fillColor: "#00FF00",
              },
            });
        
            const line = new window.google.maps.Polyline({
              path: [myLatLng1, myLatLng2],
              geodesic: true,
              strokeColor: "#00FF00",
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
        
            line.setMap(map);
        };

        const script = document.createElement("script")
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCv6Po34R_UVDD2Kr8byMWR6JCtUXBLRK0&callback=iniciarMap`;
        script.async = true;
        script.defer = true;
        //document.head.appendChild(script);
    }, [])

    return <div ref={ref} />
    return (
        
        <>
            <div id="map" className="mapouter"></div>
            {
                
            <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCv6Po34R_UVDD2Kr8byMWR6JCtUXBLRK0&callback=iniciarMap" async={true} defer={true}></script> 
                    
                
            }
        </>
    ) 
}  
    */

export default GoogleMap;