import "./AdminLogin.css";
import { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import EmailIcon from "./src/email.svg";
import PasswordIcon from "./src/password.svg";
import HidePasswordIcon from "./src/hide-pass.svg";
import ShowPasswordIcon from "./src/show-pass.svg";
import Swal from "sweetalert2";
import Loading from "../../components/loading/Loading";

function AdminLogin() {
    const [showLoading, setShowLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [usuario, setUsuario] = useState("");
    const [password, setPassword] = useState("");

    return (
        <div className="div-login-admin">
            <Loading show={showLoading} />
            <div className="form-login-admin">
                <Col lg={12}>
                    <Row>
                        <Col lg={12}>
                            <p className="p-titulo-login-admin">Administración Tabsa CL</p>
                        </Col>
                    </Row>
                    <Row className="form-field-row">
                        <Col lg={12}>
                            <Form.Control 
                                className={`form-field-login`}
                                type="text" 
                                id="txtUsername"
                                placeholder="Usuario"
                                value={usuario} 
                                onChange={(evt) => setUsuario(evt.target.value)}
                            />
                            <img className="form-field-icon" src={EmailIcon} alt="usuario" />
                        </Col>
                    </Row>
                    <Row className="form-field-row">
                        <Col lg={12}>
                            <Form.Control 
                                className={`form-field-login`}
                                type={showPassword ? "text" : "password"}
                                id="txtPassword"
                                placeholder="Password"
                                value={password} 
                                onChange={(evt) => setPassword(evt.target.value)}
                            />
                            <img className="form-field-icon" src={PasswordIcon} alt="password" />
                            <img className="show-password-icon" src={showPassword ? HidePasswordIcon : ShowPasswordIcon} alt="password" onClick={() => setShowPassword(!showPassword)} />
                        </Col>
                    </Row>
                    <Row className="form-field-row form-field-row-admin">
                        <Col lg={12}>
                            <Button className="form-button-login" onClick={() => {
                                setShowLoading(true);

                                try {
                                    fetch("https://ldap.tabsa.cl/api/auth", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify({
                                            "user": usuario,
                                            "password": password
                                        }),
                                    }).then((response) => {
                                        if (response.ok) {
                                            response.json().then(data => {

                                                if (data.error === false) {
                                                    sessionStorage.setItem("admin-user", data.user);
                                                    setShowLoading(false);
                                                    window.location.href = "/admincms";
                                                }
                                                else {
                                                    Swal.fire({
                                                        icon: "error",
                                                        title: "Error al ingresar.",
                                                        text: (data.error === "usuario" ? "No existe el usuario." : data.error === "contraseña" ? "Contraseña incorrecta." : "Favor contacta con el equipo de desarrollo.")
                                                    })
                                                    .then((alResponse) => {
                                                        setShowLoading(false);
                                                    })
                                                }
                                            })
                                        }
                                        else {
                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                            setShowLoading(false);
                                        }
                                    })
                                    
                                } catch (error) {
                                    Swal.fire({
                                        icon: "error",
                                        title: "No se pudo acceder",
                                        text: "Favor contacta con el equipo de desarrollo."
                                    })
                                    .then((alResponse) => {
                                        setShowLoading(false);
                                    })
                                }
                            }}>Acceder</Button>
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    )
}

export default AdminLogin;