import "./PRANItinerario.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../../components/Navegacion/Navegacion";
import VolverInicio from "../../../components/VolverInicio/VolverInicio";
import Chat from "../../../components/Chat/Chat";
import Footer from "../../../components/Footer/Footer";
import CLSVG from "./src/tabsa_cl.svg";
import CelularSVG from "./src/celular.svg";
import FacebookSVG from "./src/tabsa_facebook.svg";
import TwitterSVG from "./src/tabsa_twitter.svg";
import InstagramSVG from "./src/tabsa_instagram.svg";
import LogoColor from "./src/logo-tabsa-color.png";
import moment from "moment";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

function PRANItinerario() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [ruta, setRuta] = useState({});
    
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            location: window.location.href,
          });

        fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta/primera-angostura`, { method: "GET" }).then(response => {
          if (response.ok) {
            response.json().then(data => {
              setRuta(data.data);
            })
          }
          else {
            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
          }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });
  
      fetch(`https://api-cruce.tabsa.cl/api/tramos`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });
  
    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

    return (
        <div className="PRANItinerario">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-pran-itinerario-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
                ruta.imgBack &&
                <img className="img-pran-itinerario-s" alt={"pran-itinerario"} src={ruta.imgBack} />
            }
          </div>
          
          <div className="div-pran-itinerario-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                    <Row>
                        <Col lg={12}>
                            <p className="title-pran-itinerario-s">{t("nuestros-servicios.primera-angostura.itinerario.titulo")}</p>
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-pran-itinerario-content">
                
            {/* 


                <Col xl={{ span: 3, offset: 0 }} lg={{ span: 4, offset: 0 }} md={{ span: 5, offset: 0 }} sm={10} xs={10}>
                    <p className="p-sub-pran-itinerario-s">{t("nuestros-servicios.primera-angostura.itinerario.sub-t1")}</p>
                    <table className="pran-itinerario-table">
                        <thead>
                            <tr className="pran-itinerario-table-subtitle">
                                <td colSpan={4}>PUNTA DELGADA</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{width:"50%"}} className="pran-itinerario-table-td-bold">
                                    {t("nuestros-servicios.primera-angostura.itinerario.tabla-1.dias")}
                                </td>
                                <td>
                                    {t("nuestros-servicios.primera-angostura.itinerario.tabla-1.horario")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li className="p-pran-itinerario-s pran-itinerario-justify">{t("nuestros-servicios.primera-angostura.itinerario.tabla-1.li1")}</li>
                    </ul>
                    <table className="pran-itinerario-table">
                        <thead>
                            <tr className="pran-itinerario-table-subtitle">
                                <td>BAHÍA AZUL</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="pran-itinerario-table-td-bold">
                                    {t("nuestros-servicios.primera-angostura.itinerario.tabla-2.horario")}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ul>
                        <li className="p-pran-itinerario-s pran-itinerario-justify">{t("nuestros-servicios.primera-angostura.itinerario.tabla-2.li1")}</li>
                    </ul>
                </Col>
                <Col xl={{ span: 4, offset: 1 }} lg={{ span: 4, offset: 1 }} md={{ span: 5, offset: 1 }} sm={10} xs={10}>
                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <div className="pran-atencion-div-detalle">
                                <p>{t("nuestros-servicios.primera-angostura.itinerario.aviso.titulo")}</p>
                                <ul>
                                    <li className="list">{t("nuestros-servicios.primera-angostura.itinerario.aviso.li1")}</li>
                                    <li className="list">{t("nuestros-servicios.primera-angostura.itinerario.aviso.li2")}</li>
                                </ul>
                                <p className="p-detalle">{t("nuestros-servicios.primera-angostura.itinerario.aviso.detalle")}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="pran-atencion-div-info">
                                <Row className="justify-content-center">
                                    <Col lg={12}>
                                        <p className="pran-atencion-p-info">
                                            {t("nuestros-servicios.primera-angostura.itinerario.mas-info")}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col lg={12}>
                                        <p className="pran-atencion-p-info"><img src={CelularSVG} alt="phone" /> 612 728100</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={11}>
                            <img src={CLSVG} alt="tabsa.cl" className="pran-atencion-cl-image" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={11}>
                            <img src={FacebookSVG} className="pran-atencion-link-image" alt="facebook" onClick={() => { window.location.href = "https://web.facebook.com/TABSA"}} />
                            <img src={TwitterSVG} className="pran-atencion-link-image" alt="x" onClick={() => { window.location.href = "https://twitter.com/_tabsa"}} />
                            <img src={InstagramSVG} className="pran-atencion-link-image" alt="instagram" onClick={() => { window.location.href = "https://www.instagram.com/_tabsa/"}} />
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-pran-itinerario-content pran-itinerario-p-title-sep">
                <Col lg={11}>
                    <ul>
                        <li className="p-pran-itinerario-s pran-itinerario-justify">{t("nuestros-servicios.primera-angostura.itinerario.li1")}</li>
                        <li className="p-pran-itinerario-s pran-itinerario-justify">{t("nuestros-servicios.primera-angostura.itinerario.li2")}</li>
                        <li className="p-pran-itinerario-s pran-itinerario-justify">{t("nuestros-servicios.primera-angostura.itinerario.li3")}</li>
                    </ul>
                </Col>
                     */}

                <img src="https://recursos.tabsa.cl/storage/images/MtqIp9Ym.png" style={{width:1000}}>
                
                </img>


              </Row>
         
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/rutas/primera-angostura"} Texto={"volver-inicio.servicios"} />
        </div>
      );
}

export default PRANItinerario;
