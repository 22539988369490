import "./QuienesSomos.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgQuienesSomos from "./src/quienes_somos_1.png";
import ValoresA from "./src/valores_1.svg";
import ValoresB from "./src/valores_2.svg";
import ValoresC from "./src/valores_3.svg";
import ValoresD from "./src/valores_4.svg";
import ValoresE from "./src/valores_5.svg";
import ImgFoto1 from "./src/foto_1.png";
import ImgFoto2 from "./src/foto_2.png";
import ImgFoto3 from "./src/foto_3.png";
import ImgFoto4 from "./src/foto_4.png";
import circuloQuienesSomos from "./src/QuienesSomos.png";
import moment from "moment";

import { useTranslation } from "react-i18next";

function QuienesSomos() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="QuienesSomos">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-quienes-somos-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-quienes-somos-t-s" alt={"quienes somos"} src={ImgQuienesSomos} />
          </div>
          <div className="div-quienes-somos-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-content-qss-s">
                <Col lg={10} md={11} sm={11} xs={11}>
                    <p className="title-quienes-somos-s">{t("nosotros.quienes-somos.titulo-mision")}</p>
                    <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.mision")}</p>
                    <p className="title-quienes-somos-s">{t("nosotros.quienes-somos.titulo-vision")}</p>
                    <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.vision")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center row-content-qss-s">
                <Col lg={8} md={11} sm={11} xs={11}>
                    <Row className="justify-content-center">
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto1} />
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto2} />
                        </Col>
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto3} />
                        </Col>
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto4} />
                        </Col>
                    </Row>
                </Col>
              </Row>
              <Row id="valores" className="justify-content-center row-content-qss-s">
                <Col lg={10} md={11} sm={11} xs={11}>
                    <p className="title-quienes-somos-s">{t("nosotros.quienes-somos.subtitulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={10} md={11} xs={11}>

                <Row className="justify-content-center">
                    <Col className="col-valores-qs" lg={12} md={6} sm={6} xs={12}>
                      <img src={circuloQuienesSomos} width="100%" alt={t("nosotros.quienes-somos.valor-1.titulo")} />
                    </Col>
                </Row>
                
                {/*
                  <Row className="justify-content-center">
                    <Col className="col-valores-qs" lg={4} md={6} sm={6} xs={12}>
                      <img src={ValoresA} alt={t("nosotros.quienes-somos.valor-1.titulo")} />
                      <p className="subp-quienes-somos-s">{t("nosotros.quienes-somos.valor-1.titulo")}</p>
                      <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.valor-1.texto")}</p>
                    </Col>
                    <Col className="col-valores-qs" lg={4} md={6} sm={6} xs={12}>
                      <img src={ValoresB} alt={t("nosotros.quienes-somos.valor-2.titulo")} />
                      <p className="subp-quienes-somos-s">{t("nosotros.quienes-somos.valor-2.titulo")}</p>
                      <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.valor-2.texto")}</p>
                    </Col>
                    <Col className="col-valores-qs" lg={4} md={6} sm={6} xs={12}>
                      <img src={ValoresC} alt={t("nosotros.quienes-somos.valor-3.titulo")} />
                      <p className="subp-quienes-somos-s">{t("nosotros.quienes-somos.valor-3.titulo")}</p>
                      <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.valor-3.texto")}</p>
                    </Col>
                    <Col className="col-valores-qs" lg={4} md={6} sm={6} xs={12}>
                      <img src={ValoresD} alt={t("nosotros.quienes-somos.valor-4.titulo")} />
                      <p className="subp-quienes-somos-s">{t("nosotros.quienes-somos.valor-4.titulo")}</p>
                      <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.valor-4.texto")}</p>
                    </Col>
                    <Col className="col-valores-qs" lg={4} md={6} sm={6} xs={12}>
                      <img src={ValoresE} alt={t("nosotros.quienes-somos.valor-5.titulo")} />
                      <p className="subp-quienes-somos-s">{t("nosotros.quienes-somos.valor-5.titulo")}</p>
                      <p className="p-quienes-somos-s">{t("nosotros.quienes-somos.valor-5.texto")}</p>
                    </Col>
                  </Row>
                  */}

                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default QuienesSomos;
