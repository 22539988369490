import "./Rutas.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import EstadoCruce from '../components/EstadoCruce/EstadoCruce';
import Navegacion from "../components/Navegacion/Navegacion";
import VolverInicio from "../components/VolverInicio/VolverInicio";
import GoogleMap from "../components/GoogleMap/GoogleMap";
import Chat from "../components/Chat/Chat";
import Footer from "../components/Footer/Footer";
import IconoItinerario from "./src/itinerario-icon.svg";
import IconoTarifas from "./src/tarifas-icon.svg";
import IconoInformacion from "./src/informacion-icon.svg";
import IconoTerminos from "./src/terminos-icon.svg";
import IconoManual from "./src/manual-icon.svg";
import IconoNave from "./src/nave.svg";
import LogoColor from "./src/logo-tabsa-color.png";
import moment from "moment";
import ReactGA from 'react-ga4';
import MapIcon from "./src/map_1.png";
import MapNatales from "./src/Natales.PNG";
import MapPorvenir from "./src/Porvenir.PNG";
import MapPrimeraAngostura from "./src/PrimeraAngostura.PNG";
import MapRioVerde from "./src/RioVerde.PNG";
import MapWilliams from "./src/Williams.PNG";
import MapMagdalena from "./src/mapa_magdalena.png";

import { useTranslation } from "react-i18next";

function Rutas() {
    const [t, i18n] = useTranslation("global");

    const { code } = useParams();
    const [ruta, setRuta] = useState({
        titulo: { es: "", en: "es" },
        nave: "",
        contenido: []
    });
    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    
    useEffect(() => {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
        location: window.location.href,
      });
            
      fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRuta(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
      }).catch(error => {
          console.log(JSON.stringify(error));
      });

    }, [code])

    useEffect(() => {
  
      let firstDate = moment(new Date()).format("DD-MM-yyyy");
      let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");
  
      fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/ruta/${code}`, { method: "GET" }).then(response => {
        if (response.ok) {
            response.json().then(data => {
                console.log("SUCCESS")
                setRuta(data.data);
            })
        }
        else {
            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log("ERROR")
        console.log(JSON.stringify(error));
    });

      fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
          if (firstDateResponse.ok) {
              firstDateResponse.json().then(firstDateData => { 
  
                  let newCruces = firstDateData.data.map((d) => ({ 
                      estado: d.estado_detalle.toLowerCase(), 
                      origen: d.origen, 
                      destino: d.destino, 
                      hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                      fecha: d.zarpeOriginal.split(" ")[0]
                  }))
                  setCruces(newCruces);
  
                  fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                      if (secondDateResponse.ok) {
                          secondDateResponse.json().then(secondDateData => { 
                              
                              let newSecondCruces = secondDateData.data.map((d) => ({ 
                                  estado: d.estado_detalle.toLowerCase(), 
                                  origen: d.origen, 
                                  destino: d.destino, 
                                  hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                  fecha: d.zarpeOriginal.split(" ")[0]
                              }))
  
                              let concats = newCruces.concat(newSecondCruces);
                              setCruces(concats);
  
                              let newCrucesSuspendidos = [];
                  
                              concats.map((d) => {
                                  if (d.estado === "suspendido" || d.estado === "cancelado") {
                                      newCrucesSuspendidos.push(d);
                                  }
                              });
  
                              setCrucesSuspendidos(newCrucesSuspendidos);
                              setShowCruce(newCrucesSuspendidos.length > 0)
                          })
                      }
                      else {
                          console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                      }
  
                  })
              })
          }
          else {
              console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
          }
      }).catch(error => {
        console.log(JSON.stringify(error));
      });

    }, []);
  
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
  
    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
      setChatOpen(chat);
      setShowSelectLanguage(idiomas);
      setLoginOpen(login);
    }

  
      const tableStyle = {
        width: '58%',
        margin: 'auto',
        borderCollapse: 'collapse',
      };
    
      const thStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#0885C7',
        color: 'white'
      };
    
      const tdStyle = {
        border: '1px solid #ddd',
        padding: '8px'
      };
    
      const trAltStyle = {
        backgroundColor: '#f9f9f9'
      };

    return (
        <div className="Rutas">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={` d-none d-lg-block slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
          </div>
          <div className="div-rutas-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
            {
                ruta.imgBack && 
                <img className="img-rutas-s" alt={"rutas"} src={ruta.imgBack} />
            }
          </div>
          <div className="div-rutas-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={6} sm={12} xs={12}>
                  <img alt="logo" className="logo-color-xs" src={LogoColor} />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11}>
                  <Row className="justify-content-center row-rutas-s">
                    <Col lg={6} sm={11} xs={11}>
                      <p className="title-rutas-s click-rutas" onClick={() => { window.location.href = `/rutas/${code}/atencion` }}>{ ruta.titulo[t("lang")] }</p>
                    </Col>
                    <Col lg={6} sm={11} xs={11}>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={6} sm={11} xs={11}>
                      <p className="subtitle-rutas-s" onClick={() => { window.location.href = `/rutas/${code}/atencion` }}>
                        {t("nuestros-servicios.horarios")}
                        <span className="separador-rutas-s"></span>
                        {t("nuestros-servicios.oficinas")}
                        <span className="separador-rutas-s"></span>
                        {t("nuestros-servicios.informacion")}
                      </p>
                    </Col>
                      <Col lg={6} sm={11} xs={11}>
                        {code !== 'parenas-imagdalena' && (
                          <p className="p-rutas-nave click-rutas d-none d-lg-block" onClick={() => { window.location.href = `/rutas/${code}/ferry` }}>
                            <img alt="indicador" className="rutas-indicador-nave-s" src={IconoNave}/> FERRY <span className="bold">{ruta.nave}</span>
                          </p>
                        )}
                      </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center row-rutas-content">
                <Col lg={11} md={11} sm={11} xs={12}>
                    <Row className="justify-content-center">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            {
                                ruta.coords && false &&
                                <GoogleMap coords={ruta.coords} mapZoom={ruta.zoom}/>
                            }

                            {
                                (() => {
                                  switch (code) {
                                    case "parenas-pwilliams":
                                      return <img src={MapWilliams} alt="" className="img-rutas-s" />;
                                    case "parenas-porvenir":
                                      return <img src={MapPorvenir} alt="" className="img-rutas-s" />;
                                    case "pyungay-pnatales":
                                      return <img src={MapNatales} alt="" className="img-rutas-s" />;
                                    case "crioverde-islariesco":
                                      return <img src={MapRioVerde} alt="" className="img-rutas-s" />;
                                    case "primera-angostura":
                                      return <img src={MapPrimeraAngostura} alt="" className="img-rutas-s" />;
                                    case "parenas-imagdalena":
                                      return <img src={MapMagdalena} alt="" className="img-rutas-s" />;
                                    default:
                                      return <img src={MapIcon} alt="" className="img-rutas-s" />;
                                  }
                                })()
                              }
                        </Col>
                        <Col lg={6}>
                            {
                                ruta.contenido.map((c) => (<p className="p-rutas-s d-none d-lg-block">{c[t("lang")]}</p>))
                            }

                            { code == "parenas-imagdalena" && (
                              <table style={tableStyle}>
                              <thead>
                                <tr>
                                  <th style={thStyle}>Tarifa</th>
                                  <th style={thStyle}>Valor</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={tdStyle}>Adulto</td>
                                  <td style={tdStyle}>$85.000</td>
                                </tr>
                                <tr style={trAltStyle}>
                                  <td style={tdStyle}>Menor (5 a 13 años)</td>
                                  <td style={tdStyle}>$42.500</td>
                                </tr>
                                <tr>
                                  <td style={tdStyle}>Menor de 5 años</td>
                                  <td style={tdStyle}>Gratis</td>
                                </tr>
                              </tbody>
                            </table>
                            )}
                        </Col>

                    </Row>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={11} md={11} sm={11} xs={12}>
                    <Button className="button-servicio-s" onClick={() => {  
                        window.location.href = `/ruta/${code}/itinerarios`
                    }}>
                      <img alt="icono de itinerario" className="icono-servicio-itinerario" src={IconoItinerario} /> {t("nuestros-servicios.itinerario")}
                    </Button>

                    { code !== "parenas-imagdalena" && (

                    <Button className="button-servicio-s" onClick={() => {  
                      window.open(ruta.tarifas,'_blank');
                    }}>
                      <img alt="icono de tarifas" className="icono-servicio-tarifas" src={IconoTarifas} /> {t("nuestros-servicios.tarifas")}
                    </Button>

                    )}

                    <Button className="button-servicio-s" onClick={() => {  
                        window.location.href = `/rutas/${code}/info-interes`
                    }}>
                      <img alt="icono de información de interés" className="icono-servicio-informacion" src={IconoInformacion} /> {t("nuestros-servicios.info-interes")}
                    </Button>
                    {
                      ruta.terminosCondiciones &&
                      <Button className="button-servicio-s" onClick={() => {  
                          window.open(ruta.terminosCondiciones,'_blank');
                      }}>
                        <img alt="icono de términos y condiciones" className="icono-servicio-terminos" src={IconoTerminos} /> {t("nuestros-servicios.terminos")}
                      </Button>
                    }
                    {
                      ruta.manualOperaciones &&
                      <Button className="button-servicio-s" onClick={() => {  
                          window.open(ruta.manualOperaciones,'_blank');
                      }}>
                        <img alt="icono de mapa" className="icono-servicio-mapa" src={IconoManual} /> {t("nuestros-servicios.manual")}
                      </Button>
                    }
                </Col>
              </Row>
              <Row className="justify-content-center d-block d-lg-none">
                <Col lg={11} md={11} sm={11} xs={12}>
                  <p className="p-rutas-nave click-rutas d-block d-lg-none" onClick={() => { window.location.href = `/servicios/${code}/ferry` }}>
                    <img alt="indicador" className="icono-nave-s" src={IconoNave}/> FERRY <span className="bold">{ ruta.nave }</span>
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center hidden-lg">
                <Col lg={11} md={11} sm={11} xs={12}>
                  {
                    ruta.contenido.map((c) => (<p className="p-rutas-s d-block d-lg-none">{c[t("lang")]}</p>))
                  }
                </Col>

                
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
      );
}

export default Rutas;
