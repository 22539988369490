import './App.css';
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import EstadoCruce from '../components/EstadoCruce/EstadoCruce';
import SliderViajes from '../components/SliderViajes/SliderViajes';
import NuestrosServicios from '../components/NuestrosServicios/NuestrosServicios';
import Banners from '../components/Banners/Banners';
import Footer from '../components/Footer/Footer';
import PagarImprimir from '../components/PagarImprimir/PagarImprimir';
import Chat from '../components/Chat/Chat';
import Notificaciones from '../components/Notificaciones/Notificaciones';
import moment from "moment";
import Modals from "../components/Modals/Modals";
import ReactGA from 'react-ga4';

function App() {
  const [cruces, setCruces] = useState([]);
  const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
  const [showCruce, setShowCruce] = useState(false);

  const [banners, setBanners] = useState([]);
  const [rutas, setRutas] = useState([]);
  
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      location: window.location.href,
    });
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))

                let crucesPA = firstDateData.primera_angostura.map((d) => ({ 
                  estado: d.estado == 4? 'suspendido' : 'normal', 
                  origen: 'Primera Angostura', 
                  destino: 'Primera Angostura', 
                  hora: d.timestamp_suspension.split(" ")[1].substring(0, 5), 
                  fecha: d.timestamp_suspension.split(" ")[0]
                }))

                let crucesRV = firstDateData.rio_verde.map((d) => (
                  { 
                  estado: d.estado == 4? 'suspendido' : 'normal', 
                  origen: 'Río Verde', 
                  destino: 'Río Verde', 
                  hora: d.timestamp_suspension.split(" ")[1].substring(0, 5), 
                  fecha: d.timestamp_suspension.split(" ")[0],
                  id_estado: d.estado
                }))

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            concats =concats.concat(crucesPA);
                            concats =concats.concat(crucesRV);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCruces(concats);
                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Banners/banners`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setBanners(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });

  }, []);

  const [chatOpen, setChatOpen] = useState(false);
  const [calendarFechaIda, setCalendarFechaIda] = useState(false);
  const [calendarFechaVuelta, setCalendarFechaVuelta] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setCalendarFechaIda(calendarioIda);
    setCalendarFechaVuelta(calendarioVuelta);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

  return (
    <div className="App" translate="no">
      <meta name="google" content="notranslate" />
      <meta name="google-site-verification" content="PlZ7tpfHWOFVK6nFYloaOVr40jj7NdtQfM1EqI1KMg4" />
      <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
      <SliderViajes clickOut={showItems} showCruce={showCruce} rutas={rutas} calendarFechaIda={calendarFechaIda} calendarFechaVuelta={calendarFechaVuelta} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
      <Chat clickOut={showItems} chatOpen={chatOpen} />
      <NuestrosServicios clickOut={showItems} />
      <Row className="justify-content-center div-listado-servicios">
        <Col lg={4} sm={5} xs={12}>
          {
            banners && banners.filter(t => t.position === "l").length > 0 &&
            <img className={`img-home ${banners.filter(t => t.position === "l")[0].url ? "img-clickable": ""}`} src={banners.filter(t => t.position === "l")[0].img} alt='home-l' 
              onClick={() => {
                if (banners.filter(t => t.position === "l")[0].url) {
                  window.open(banners.filter(t => t.position === "l")[0].url, "_blank")
                }
              }}
            />
          }
        </Col>
        <Col lg={4} sm={5} xs={12}>
          {
            banners && banners.filter(t => t.position === "r").length > 0 &&
            <img className={`img-home ${banners.filter(t => t.position === "r")[0].url ? "img-clickable": ""}`} src={banners.filter(t => t.position === "r")[0].img} alt='home-r' 
              onClick={() => {
                if (banners.filter(t => t.position === "r")[0].url) {
                  window.open(banners.filter(t => t.position === "r")[0].url, "_blank")
                }
              }}
            />
          }
        </Col>
      </Row>
      <Banners clickOut={showItems}/>
      <Notificaciones />
      <Footer clickOut={showItems}/>
      <PagarImprimir clickOut={showItems}/>
      <Modals />
    </div>
  );
}

export default App;
